//***************** Plaid Configuration *****************;


module.exports = {
    PlaidConfiguration:
  {
    PLAID_PUBLIC_KEY: '8cf884dfae9bec2d078a35522ab9ae',
    PLAID_WEB_VIEW_REDIRECT_URI: "njtRetailer://redirectPalid",
    ENV: "sandbox"


  },
};
