import { UserAgreementVersionStatus, UserAgreementVersionActions } from './UserAgreementVersionConstants';

const UserAgreementVersion_initialState = {
    UserAgreementVersion_Status: UserAgreementVersionStatus.UserAgreementVersion_GET.NEW,
    UserAgreementVersion_Error: '',
    UserAgreementVersion: [],
}
export default  function  (state = UserAgreementVersion_initialState, action) {
    switch (action.type) {
        case UserAgreementVersionActions.UserAgreementVersion_GET.LOADING:
            return { ...state, UserAgreementVersion_Status: UserAgreementVersionStatus.UserAgreementVersion_GET.LOADING }
            case UserAgreementVersionActions.UserAgreementVersion_GET.NEW:
                return { ...state, UserAgreementVersion_Status: UserAgreementVersionStatus.UserAgreementVersion_GET.NEW }
    
        case UserAgreementVersionActions.UserAgreementVersion_GET.FAILED:
            return { ...state,  UserAgreementVersion_Status: UserAgreementVersionStatus.UserAgreementVersion_GET.FAILED}
        case UserAgreementVersionActions.UserAgreementVersion_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  UserAgreementVersion_Status: UserAgreementVersionStatus.UserAgreementVersion_GET.SUCCESS, UserAgreementVersion:action.payload}
        
        default:
            return { ...state,
            }
    }
}
