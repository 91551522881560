
//******************Exchange Rate Server Calls******************;

import { GetStatesActions } from './GetStatesConstants';
import store from '../../../Store/store'
const USContry={"id":233,"name":"United States","iso2":"US"}

export const GetStatesServer = {
  //  GetStates:GetStates,
  getGetStates: getGetStates,
  //  updateGetStates:updateGetStates
};

//****************** GetStates with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function getGetStates() {
  let headers = new Headers();
  headers.append(
    'X-CSCAPI-KEY',
    'VnR4QWdkM3RLWk9OZTFDelBTdUljZDdCMXRiQ3lKZEVuRXUwUDVpbQ=='
  );
  let requestOptions = {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
  };
  // Pass Country Code -- Eg: Country Code : IN
  fetch(
    `https://api.countrystatecity.in/v1/countries/${USContry.iso2}/states`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {

      if (result.error == 'Not found.') {
        store.dispatch({ type: GetStatesActions.GetStates_GET.FAILED });
      } else {
        let NJ = {}
        // alert(JSON.stringify(result))
        let FilteredArray = result.filter(a=>{

          a.label = a.name+ " ("+a.iso2+")";
          a.value = a.iso2
          if(a.name === "New Jersey")
          {
            NJ = a
            return false
          }
          else 
          return true
        })
        let sortedArray = FilteredArray.sort((a, b) =>  a.name.localeCompare(b.name))

        //   if(a.name === "New Jersey" || b.name === "New Jersey")
        //   alert("test")
        //   a.label = a.name;
        //   b.label = b.name;
        // alert(JSON.stringify(NJ))
        //   return a.name.localeCompare(b.name)})
          // alert(JSON.stringify(sortedArray))
          sortedArray.push({"id":1446,"label":"Others","value":""})
          sortedArray.unshift(NJ)
          // alert(JSON.stringify(sortedArray))
          store.dispatch({ type: GetStatesActions.GetStates_GET.SUCCESS, payload: sortedArray});
        }
          
    

  //     if (response.status == "200") {
  //       // console.log('Business quota details', response.data)
  //       console.log("States to call from db", response.data)
  // //****************** responce.data contains values of business Quotas along with business crontact
  // //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
  //       //console.log(response.data)


  //       GetStates.unshift({value:'', name: 'Select'})

  //       store.dispatch({ type: GetStatesActions.GetStates_GET.SUCCESS, payload: GetStates});
  //       return;
  //     } else {
  //       store.dispatch({ type: GetStatesActions.GetStates_GET.FAILED });
  //       return;
  //     }
    }).catch((error) => {
      store.dispatch({ type: GetStatesActions.GetStates_GET.FAILED });
    })
  
  return { type: GetStatesActions.GetStates_GET.LOADING }

};
