import React, { Component } from 'react'
import "./Loading.css"
class Loading extends Component {
    render() {
        return (
            <div className="loading-b">
               <div className="spinner-border loading-color"></div>
            </div>
        )
    }
}

export default Loading
