import { GetFaceValueStatus, FaceValueActions } from './GetFaceValueConstants';

const GetFaceValue_initialState = {
    GetFaceValue_Status: GetFaceValueStatus.GetFaceValue_GET.NEW,
    GetFaceValue_Error: '',
    GetFaceValue: [],
}
export default  function  (state = GetFaceValue_initialState, action) {
    switch (action.type) {
        case FaceValueActions.GetFaceValue_GET.LOADING:
            return { ...state, GetFaceValue_Status: GetFaceValueStatus.GetFaceValue_GET.LOADING }
            case FaceValueActions.GetFaceValue_GET.NEW:
                return { ...state, GetFaceValue_Status: GetFaceValueStatus.GetFaceValue_GET.NEW }
    
        case FaceValueActions.GetFaceValue_GET.FAILED:
            return { ...state,  GetFaceValue_Status: GetFaceValueStatus.GetFaceValue_GET.FAILED, GetFaceValue_Error:action.error}
        case FaceValueActions.GetFaceValue_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetFaceValue_Status: GetFaceValueStatus.GetFaceValue_GET.SUCCESS, GetFaceValue:action.payload}
        
        default:
            return { ...state,
            }
    }
}
