import React, { Component } from 'react'
import './NjtFooter.css'
import { Link } from 'react-router-dom';
import {RIDER_WEB_URL,VERSION_NO,LANDING_PAGE_END_BRANDING_IMAGE_URL} from "../../Config/Config"
import { NJTContactUsURL } from '../../Config/Routes';

class NjtFooter extends Component {

    render() {
        return (
            <footer className="njt-footer-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <div className="njt-footer-log">
                            <img
                            src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Njt-Logo-All.svg"}
                            className="njt-img-4"
                            alt="not found"
                            />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="njt-footer-link">
                                <ul className="njt-list-menu">
                                    <li><a target={"_blank"} aria-label="Contact Us Link" href={NJTContactUsURL}>{this.props.NJTLandingPage.FooterSection.ContactUs}</a></li>
                                    <li><Link aria-label="FAQs Link" onClick={window.scrollTo(0, 0)} to="/FAQs">{this.props.NJTLandingPage.FooterSection.FAQ}</Link></li>
                                    <li><a target={"_blank"} rel="noopener noreferrer" href={RIDER_WEB_URL}>{this.props.NJTLandingPage.FooterSection.RiderRedirectLink}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="njt-cop-last">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="njt-footer-copy desktop">
                                {/* 2021 © NJ TRANSIT All Rights Reserved. */}
                                {this.props.NJTLandingPage.FooterSection.CopyRightText}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="footer-use-njt">
                            <a href="/termsofuse" target={"_blank"}>{this.props.NJTLandingPage.FooterSection.TermsOfUse}</a>
                                {/* <Link onClick={window.scrollTo(0, 0)} to="/termsofuse">{this.props.NJTLandingPage.FooterSection.TermsOfUse}</Link> */}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="njt-footer-copy mobile">
                                {/* 2021 © NJ TRANSIT All Rights Reserved. */}
                                {this.props.NJTLandingPage.FooterSection.CopyRightText}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="version-get-b">
                    <div className="version">
                    {VERSION_NO}
                    </div>
                 </div>
            </footer>
        )
    }
}

export default NjtFooter
