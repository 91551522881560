import { useEffect, useState } from "react";
import React from "react"

// import { getCookie,hasCookie, setCookie, deleteCookie } from 'cookies';
import { withCookies, Cookies } from 'react-cookie';
import DropDownInput from "../Components/Inputs/DropDownInput";
import { COOKIE_DOMAIN } from "../Config/Config";
const google = window.google

const languages = [
    { name: 'English', value:'/auto/en' },
    { name: 'Spanish', value:'/auto/es' },
    { name: 'Afrikaans', value:'/auto/af' },
    { name: 'Albanian', value:'/auto/sq' },
    { name: 'Amharic', value:'/auto/am' },
    { name: 'Arabic', value:'/auto/ar' },
    { name: 'Armenian', value:'/auto/hy' },
    { name: 'Assamese', value:'/auto/as' },
    { name: 'Basque', value:'/auto/eu' },
    { name: 'Bengali', value:'/auto/bn' },
    { name: 'Bosnian', value:'/auto/bs' },
    { name: 'Bulgarian', value:'/auto/bg' },
    { name: 'Catalan', value:'/auto/ca' },
    { name: 'Chinese (Simplified)', value:'/auto/zh-CN' },
    { name: 'Chinese (Traditional)', value:'/auto/zh-TW' },
    { name: 'Corsican', value:'/auto/co' },
    { name: 'Croatian', value:'/auto/hr' },
    { name: 'Czech', value:'/auto/cs' },
    { name: 'Danish', value:'/auto/da' },
    { name: 'Dhivehi', value:'/auto/dv' },
    { name: 'Dutch', value:'/auto/nl' },
    { name: 'Esperanto', value:'/auto/eo' },
    { name: 'Estonian', value:'/auto/et' },
    { name: 'Filipino', value:'/auto/tl' },
    { name: 'Finnish', value:'/auto/fi' },
    { name: 'French', value:'/auto/fr' },
    { name: 'Frisian', value:'/auto/fy' },
    { name: 'Galician', value:'/auto/gl' },
    { name: 'Georgian', value:'/auto/ka' },
    { name: 'German', value:'/auto/de' },
    { name: 'Greek', value:'/auto/el' },
    { name: 'Gujarati', value:'/auto/gu' },
    { name: 'Haitian Creole', value:'/auto/ht' },
    { name: 'Hausa', value:'/auto/ha' },
    { name: 'Hawaiian', value:'/auto/haw' },
    { name: 'Hebrew', value:'/auto/iw' },
    { name: 'Hindi', value:'/auto/hi' },
    { name: 'Hungarian', value:'/auto/hu' },
    { name: 'Icelandic', value:'/auto/is' },
    { name: 'Igbo', value:'/auto/ig' },
    { name: 'Indonesian', value:'/auto/id' },
    { name: 'Irish Gaelic', value:'/auto/ga' },
    { name: 'Italian', value:'/auto/it' },
    { name: 'Japanese', value:'/auto/ja' },
    { name: 'Javanese', value:'/auto/jw' },
    { name: 'Kannada', value:'/auto/kn' },
    { name: 'Kazakh', value:'/auto/kk' },
    { name: 'Khmer', value:'/auto/km' },
    { name: 'Kinyarwanda', value:'/auto/rw' },
    { name: 'Konkani', value:'/auto/gom' },
    { name: 'Korean', value:'/auto/ko' },
    { name: 'Kurdish (Kurmanji)', value:'/auto/ku' },
    { name: 'Kyrgyz', value:'/auto/ky' },
    { name: 'Lao', value:'/auto/lo' },
    { name: 'Latin', value:'/auto/la' },
    { name: 'Latvian', value:'/auto/lv' },
    { name: 'Lingala', value:'/auto/ln' },
    { name: 'Lithuanian', value:'/auto/lt' },
    { name: 'Luganda', value:'/auto/lg' },
    { name: 'Luxembourgish', value:'/auto/lb' },
    { name: 'Macedonian', value:'/auto/mk' },
    { name: 'Maithili', value:'/auto/mai' },
    { name: 'Malagasy', value:'/auto/mg' },
    { name: 'Malay', value:'/auto/ms' },
    { name: 'Malayalam', value:'/auto/ml' },
    { name: 'Maltese', value:'/auto/mt' },
    { name: 'Maori', value:'/auto/mi' },
    { name: 'Marathi', value:'/auto/mr' },
    { name: 'Meiteilon (Manipuri)', value:'/auto/mni-Mtei' },
    { name: 'Mizo', value:'/auto/lus' },
    { name: 'Mongolian', value:'/auto/mn' },
    { name: 'Myanmar (Burmese)', value:'/auto/my' },
    { name: 'Nepali', value:'/auto/ne' },
    { name: 'Norwegian', value:'/auto/no' },
    { name: 'Odia (Oriya)', value:'/auto/or' },
    { name: 'Oromo', value:'/auto/om' },
    { name: 'Pashto', value:'/auto/ps' },
    { name: 'Persian', value:'/auto/fa' },
    { name: 'Polish', value:'/auto/pl' },
    { name: 'Portuguese', value:'/auto/pt' },
    { name: 'Punjabi', value:'/auto/pa' },
    { name: 'Quechua', value:'/auto/qu' },
    { name: 'Romanian', value:'/auto/ro' },
    { name: 'Russian', value:'/auto/ru' },
    { name: 'Samoan', value:'/auto/sm' },
    { name: 'Sanskrit', value:'/auto/sa' },
    { name: 'Scots Gaelic', value:'/auto/gd' },
    { name: 'Sepedi', value:'/auto/nso' },
    { name: 'Serbian', value:'/auto/sr' },
    { name: 'Sesotho', value:'/auto/st' },
    { name: 'Shona', value:'/auto/sn' },
    { name: 'Sindhi', value:'/auto/sd' },
    { name: 'Sinhala', value:'/auto/si' },
    { name: 'Slovak', value:'/auto/sk' },
    { name: 'Slovenian', value:'/auto/sl' },
    { name: 'Somali', value:'/auto/so' },
    { name: 'Sundanese', value:'/auto/su' },
    { name: 'Swahili', value:'/auto/sw' },
    { name: 'Swedish', value:'/auto/sv' },
    { name: 'Tajik', value:'/auto/tg' },
    { name: 'Tamil', value:'/auto/ta' },
    { name: 'Tatar', value:'/auto/tt' },
    { name: 'Telugu', value:'/auto/te' },
    { name: 'Thai', value:'/auto/th' },
    { name: 'Tigrinya', value:'/auto/ti' },
    { name: 'Tsonga', value:'/auto/ts' },
    { name: 'Turkish', value:'/auto/tr' },
    { name: 'Turkmen', value:'/auto/tk' },
    { name: 'Twi', value:'/auto/ak' },
    { name: 'Ukrainian', value:'/auto/uk' },
    { name: 'Urdu', value:'/auto/ur' },
    { name: 'Uyghur', value:'/auto/ug' },
    { name: 'Uzbek', value:'/auto/uz' },
    { name: 'Vietnamese', value:'/auto/vi' },
    { name: 'Welsh', value:'/auto/cy' },
    { name: 'Xhosa', value:'/auto/xh' },
    { name: 'Yiddish', value:'/auto/yi' },
    { name: 'Yoruba', value:'/auto/yo' },
    { name: 'Zulu', value:'/auto/zu' }
];

const GoogleTranslate = (props) => {

const [selected, setSelected] = useState(false)
const { cookies } = props;

useEffect(() => {
    // alert(COOKIE_DOMAIN)
    // alert(getCookie('googtrans'))
    if(cookies.get('googtrans')){
        // alert(getCookie('googtrans'))
        setSelected(cookies.get('googtrans'))
        }
        else{
        setSelected('/auto/en')
        }

}, [])



useEffect(() => {
  if(selected){
    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }
}, [selected])

const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({
        pageLanguage: 'auto',
        autoDisplay: false,
        // includedLanguages: "ru,en,pl", // If you remove it, by default all google supported language will be included
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
    },
    'google_translate_element');
}
const langChange=(e,m,evt)=>{
    if (evt) {
        evt.preventDefault();
    }
    const value = e.target.value
    cookies.remove('googtrans')
   
    cookies.set('googtrans',decodeURI(value), {domain:COOKIE_DOMAIN})
    setSelected(value)
  
    window.location.reload()
    }

    return (
        <>

            {/* <div id="google_translate_element" style={{width:'0px',height:'0px',position:'absolute',left:'50%',zIndex:-99999}}></div> */}
            <DropDownInput 
             data={languages} 
             style={{ width: 100 }} 
            //  placement="bottomEnd"
            //  cleanable={false}
             inputValue={selected}
            //  searchable={false}
            //  className={'notranslate'}
            //  menuClassName={'notranslate'}
            onChange={langChange}
             placeholder="Lang"
             /> 
        </>

    )
}

export default withCookies(GoogleTranslate)
