import React, { Component } from 'react'
import "./TransitPerxFooter.css"

class TransitPerxFooter extends Component {
    render() {
        return (
            <div className="perx-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="perx-logo-footer">
                            <img src="https://transitperxbranding.s3.amazonaws.com/perx-new-logo.svg" alt="not found" />
                                <span className="perx-logo-txt">TransitPerx</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="link-perx-footer">
                                <a className="perx-footer-link" href="#schedule">Schedule a Demo</a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="perx-logo-copy">
                            2021 © TransitPerx All Rights Reserved.
                            </div>
                        </div>
                        <div className="sol-sm-6">
                            {/* <div className="link-perx-footer">
                                <a className="perx-footer-link" href="">Schedule a Demo</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransitPerxFooter
