import React, { Component, } from 'react'
import '../NjtHomeOne/NjtHomeOne.css'
import NjtFAQs from '../FAQs/NjtFAQs'
import { NJTContactUsURL } from '../../Config/Routes';

class NjtFaqPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          zohoLoading: false,
          showThankYou: false,
        };
      }
    

    componentDidMount() {
        window.scrollTo(0, 0);
        console.log("link data", this.props.NJTLandingPage.FAQSection.TextBeforeLink)
      }



    render() {
        return (
            <main className="njt-faq-wrap">
                
                <div className="njt-faq-body">
                        <div className="njt-faq-box">
                            <NjtFAQs FAQ={this.props.NJTLandingPage.FAQSection}/>
                        </div>
                    <div className="njt-note-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="njt-not-txt">
                                {/* <b>Note:</b> You can also reach us at <a href="">(201) 298-8636</a>. */}
                                {/* <ReactMarkdown plugins={[gfm]} source={this.props.NJTLandingPage.ContactUsSection.Note} allowDangerousHtml></ReactMarkdown> */}

                                {
                                this.props.NJTLandingPage ?
                                    <p>{this.props.NJTLandingPage.FAQSection.TextBeforeLink}{" "}<a aria-label="Contact Us Link" href={NJTContactUsURL} target={"_blank"}>{this.props.NJTLandingPage.FAQSection.LinkText}</a>{this.props.NJTLandingPage.FAQSection.AfterLinkText}</p>
                                :
                                    <p>If you didn't find the answer to your question,{" "}<a aria-label="Contact Us Link" href={NJTContactUsURL} target={"_blank"}>contact us</a>.</p>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </main>
        )
    }
}

export default NjtFaqPage
