import React, { Component, Fragment } from "react";
import "../TermAndCondition/TermAndCondition.css";
import { withRouter } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import {LANDING_PAGE_END_BRANDING_IMAGE_URL} from '../../Config/Config'

class TermAndCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisable:true,
      isEnable: false
    };
    if(this.props.TermAndConditions && this.props.TermAndConditions.Description)
    {
      if(this.props.TermAndConditions.Description.length < 500)
      this.state.isDisable = false

      
      
    }
  }
 

  render() {
    const txt="Termsfor the NJT Rewards New Jersey Transit Terms of Use for the NJT Rewards New Jersey Transit Terms of Use for the NJT Rewards New Jersey Transit Terms of Use for the NJT Rewards New Jersey Transit Terms of Use for the NJT Rewards New Jersey Transit Terms of Use for the NJT RewardsNew Jersey Transit Terms of Use for the NJT Rewards New Jersey Transit Terms of Use for the NJT Rewards  New Jersey Transit Terms of Use for the NJT Rewards  New Jersey Transit Terms of Use for the NJT Rewards"
    console.log("lenght",txt.length<500)
    console.log("terms", this.props.TermAndConditions)
    
    return (
      <Fragment>


          
      < main className="term-codition">
      {/* --------- Header Section Start ---------------*/}
      
      <div className="term-head-boxx">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="term-headig">Partner Terms of Use</h1>
                        </div>
                    </div>
                </div>
            </div>
      {/* ----------------- Header Section End ---------------*/}

      <div className="term-slider-box">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                        <div className="main-slider-term">
                     
                        <img src={this.props.TermAndConditions.TermAndConditionImage ? this.props.TermAndConditions.TermAndConditionImage.url :LANDING_PAGE_END_BRANDING_IMAGE_URL + "HeaderImagef.jpg"} alt="Terms of Use Image" />
            
                        </div>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>
            {/* ----------------- term-slider-box Section End ---------------*/}
      <div className="term-box" id="section1">

          <div className="container">
            <div className="row">
          
              <div className="col-sm-12 col-md-12 col-lg-12">
            
                <div className="term-inner-box" 
                
                 style={{ overflowY: "auto", maxHeight: '90vh'}}
                
                 >
                  <div className="term-txt">
                      <ReactMarkdown plugins={[gfm]} source={this.props.TermAndConditions.Description} allowDangerousHtml></ReactMarkdown>  
                  </div> 
                </div>
              </div>
            </div>
          </div>
      </div>

      
      
      </ main>
     
      </Fragment>
    );
  }
}

export default withRouter(TermAndCondition);
