import React, { Component } from "react";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import "./NjtHomeOne.css";
import {Link} from "react-router-dom"
import {LANDING_PAGE_END_BRANDING_IMAGE_URL} from "../../Config/Config"
import HeroSection from "../NewHomeLayout/HeroSection/HeroSection";
import SectionOne from "../NewHomeLayout/SectionOne/SectionOne";
import SectionTwo from "../NewHomeLayout/SectionTwo/SectionTwo";
import rewardImage from "../../Assets/Images/reward-image.png"
import uniqueImage from "../../Assets/Images/unique-image.png"
import localImage from "../../Assets/Images/localImage.png"
import reachImage from "../../Assets/Images/large-reach-image.png"
import SectionThree from "../NewHomeLayout/SectionThree/SectionThree";
import lastImage from "../../Assets/Images/last-image.png"
import heroImage from "../../Assets/Images/restaurant-njt-transit.png"


class NjtHomeOne extends Component {

  
  componentDidMount() {

    if(this.props.location.hash !== ""){
      let scrollId = this.props.location.hash.substring(1)
      let scrollDiv = document.getElementById(scrollId)
      
      if(scrollDiv)
        scrollDiv.scrollIntoView()
    }
    else{
      window.scrollTo(0, 0);
    }

    console.log("location hdhdhd",this.props.location)
    // console.log("NJT Retailer Content\n", this.props.NJTLandingPage.Section4.Image_2.url)
    console.log("NJT Retailer Content\n", this.props.NJTLandingPage.Section1.Heading)
    console.log("NJT Retailer Content\n", this.props.NJTLandingPage.Section1.Description)
    //console.log("NJT Retailer Content\n", this.props.NJTLandingPage.Section3.Image_1.url)
  }

  render() {
    return (
      <main className="njt-home-wrap">
        <div className="njt-home-box">
          {/*** Header Mobile end ***/}
          {/* <NjtHeader/> */}
          <div className="main-njt-body">
            <HeroSection
            heading={this.props.NJTLandingPage 
              ?
              <ReactMarkdown
              source={this.props.NJTLandingPage.Section1.Heading}
              allowDangerousHtml
            ></ReactMarkdown>
            :
            "Promote Your Business with NT&nbsp;TRANSIT for FREE"
          }
            heroImage={this.props.NJTLandingPage.Section1.Image_1.url}
            link={this.props.NJTLandingPage.Section1.Link}
            linkText={this.props.NJTLandingPage.Section1.LinkText}
            />
            <SectionOne
            heading={this.props.NJTLandingPage.Section2.Text_1}
            paragraph={
            <ReactMarkdown 
              source={this.props.NJTLandingPage.Section2.Paragraph} 
              allowDangerousHtml>
              </ReactMarkdown>}
            image={this.props.NJTLandingPage.Section2.Image_1.url}
            altText={"NJT Rewards® Image"} 
            />
            <SectionTwo
            heading = {this.props.NJTLandingPage.Section3.Heading}
            image={this.props.NJTLandingPage.Section3.Image_1.url} 
            boldParagraph ={<ReactMarkdown 
              plugins={[gfm]} 
              source={this.props.NJTLandingPage.Section3.Text_1} 
              allowDangerousHtml>
              </ReactMarkdown>}   
            paragraph1 = {<ReactMarkdown 
              plugins={[gfm]} 
              source={this.props.NJTLandingPage.Section3.Text_2} 
              allowDangerousHtml>
              </ReactMarkdown>} 
            paragraph2 = {<ReactMarkdown 
              plugins={[gfm]} 
              source={this.props.NJTLandingPage.Section3.Text_3} 
              allowDangerousHtml>
              </ReactMarkdown>}  
            altText = "unique approach image"
            />
            <div className="njt-section-4">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="njt-banner-img-box">
                        <img
                          src={this.props.NJTLandingPage.Section4.Image_1.url}
                          className="njt-img-3"
                          alt="Points Event Image"
                        />
                      
                      </div>
                    </div>
                  </div>
                </div>
             </div>
             <SectionOne
             heading={this.props.NJTLandingPage.Section5.Heading}
             paragraph={<ReactMarkdown 
              plugins={[gfm]} 
              source={this.props.NJTLandingPage.Section5.Paragraph} 
              allowDangerousHtml>
              </ReactMarkdown>} 
             image={this.props.NJTLandingPage.Section5 ? this.props.NJTLandingPage.Section5.Image_1.url : localImage}
             altText={"NJT Rewards® Image"} 
             />
             <SectionThree
              image={this.props.NJTLandingPage.Section6 ?this.props.NJTLandingPage.Section6.Image_1.url : lastImage}
              altText={"NJT Rewards® Image"} 
              heading={<ReactMarkdown 
                plugins={[gfm]} 
                source={this.props.NJTLandingPage.Section6.Heading} 
                allowDangerousHtml>
                </ReactMarkdown>} 
              link={this.props.NJTLandingPage.Section6.Link}
              linkText={this.props.NJTLandingPage.Section6.LinkText}
             />
          {/* <div className="hero-section transit-section-1">
              <div className="container">
                <div className="inner-hero-njt">
                  <div className="row align-items-center">
                  <div className="col-sm-12 mobile">
                      <div className="mob-res">
                        <img
                          src={this.props.NJTLandingPage.Section1.Image_1.url}
                          className="image1"
                          alt="NJT Rewards® Image"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                      <div className="njt-hadle-box">
                        <div className="njt-section1-heading">
                          {this.props.NJTLandingPage ?
                          <ReactMarkdown
                            source={this.props.NJTLandingPage.Section1.Heading}
                            allowDangerousHtml
                          ></ReactMarkdown>
                          
                           : <h2  className="njt-heading1">GROW YOUR BUSINESS WITH NJ&nbsp;TRANSIT!</h2> 
                          }
                        </div>                        
                        <div className="njt-section2-heading">
                          {this.props.NJTLandingPage ?
                            <ReactMarkdown
                            source={this.props.NJTLandingPage.Section1.Description}
                            allowDangerousHtml> 
                            </ReactMarkdown>

                           :<div className="njt-text-app">NJ&nbsp;TRANSIT is launching a new and exciting program called NJT&nbsp;Rewards which gives customers points for their ticket purchases. Points can be redeemed for offers bringing customers to your business.</div> 
                          }
                        </div>

                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 deskstop">
                      <div className="mob-res">
                        <img
                          src={this.props.NJTLandingPage.Section1.Image_1.url}
                          className="image1"
                          alt="NJT Rewards® Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <!-- .hero-section end --> */}
            {/* <section id="njt-three" >
            <div className="njt-section-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h2 className="njt-heading-3">
                      {this.props.NJTLandingPage.Section3.Heading}
                    </h2>
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-12">
                  <div className="img-njt-4 mobile">
                        <img
                          src={this.props.NJTLandingPage.Section3 ? this.props.NJTLandingPage.Section3.Image_1.url:(LANDING_PAGE_END_BRANDING_IMAGE_URL+"why-image.png")}
                          className="njt-img-2"
                          alt="Get started today image"
                        />
                    </div>
                </div>
                  <div className="col-sm-12 col-md-7">
                    <div className="njt-today-box">
                        <ReactMarkdown plugins={[gfm]} source={this.props.NJTLandingPage.Section3.Text_1} allowDangerousHtml></ReactMarkdown>
                      <div className="njt-tody-paraght">
                      {this.props.NJTLandingPage.Section3.Text_2}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="img-njt-4 deskstop">
                        <img
                          src={this.props.NJTLandingPage.Section3 ? this.props.NJTLandingPage.Section3.Image_1.url:(LANDING_PAGE_END_BRANDING_IMAGE_URL+"why-image.png")}
                          className="njt-img-2"
                          alt="Get started today image"
                        />
                    </div>
                  </div>
                </div>
              </div>
                 
            <div className="new-sign-page-design">
               <div className="container">
                 <div className="row">
                   <div className="col-sm-12">
                     <div className="new-sign-btn-box">
                       <Link to="/SignUp">Business Sign Up</Link>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
            </div>
            </section> */}
             {/* .njt-section-3 end */}
            {/* <div className="njt-section-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                  <h2 className="njt-heading-2">   
                    {this.props.NJTLandingPage.Section2.Text_1}
                  </h2>
                  </div>
                </div>
                <div className="njt-why-sec">
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-6">
                      <div className="img-njt-3">
                        <img
                          src={this.props.NJTLandingPage.Section2.Image_1.url}
                          className="njt-img-2"
                          alt="Benefits for business image"
                        />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="njt-why-box">
                        <div className="njt-why-des">
                          
                            <ReactMarkdown plugins={[gfm]} source={this.props.NJTLandingPage.Section2.Bullet_List} allowDangerousHtml></ReactMarkdown>
                   
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
           
          </div>
        </div>
      </main>
    );
  }
}

export default NjtHomeOne;
