import { connect } from "react-redux";
import NjtSignUpFormView from "./NjtSignUpForm_View";
import {NjtSignUpFormServer} from "./server"
import {NjtSignUpFormActions} from './NjtSignUpFormConstants'
import {withRouter} from "react-router-dom"

const mapStateToProps = state => {
    console.log(state)
    return {
        NjtSignUpForm_post_status:state.NjtSignUpFormReducer.NjtSignUpForm_Status,
        NjtSignUpForm_Message:state.NjtSignUpFormReducer.NjtSignUpForm_Message,
        NjtSignUpForm:state.NjtSignUpFormReducer.NjtSignUpFormDetails
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateNjtSignUpForm: (data, bussinessId) => {
        //     dispatch( NjtSignUpFormServer.updateNjtSignUpForm(data, bussinessId) )
        //  },

        getNjtSignUpForm: (data, token) => {

           dispatch( NjtSignUpFormServer.getNjtSignUpForm(data, token) )
        },
        resetReducerState: () => {
            dispatch({type:NjtSignUpFormActions.NjtSignUpForm_Post.NEW})
         },
        
    }
};
const NjtSignUpFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NjtSignUpFormView)

export default withRouter(NjtSignUpFormContainer);