import { BusinessProfileStatus, BusinessProfileActions } from './BusinessProfileConstants';

const BusinessProfile_initialState = {
    BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.NEW,
    BusinessProfile_Message: '',
    BusinessProfileDetails: {},
    Flags:false
}
export default function (state = BusinessProfile_initialState, action) {
    switch (action.type) {
        case BusinessProfileActions.BusinessProfile_Post.LOADING:
            return { ...state, 
            BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.LOADING,BusinessProfile_Message: '',
            BusinessProfileDetails: {}
         }
        case BusinessProfileActions.BusinessProfile_Post.NEW:
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.NEW}

        case BusinessProfileActions.BusinessProfile_Post.FAILED:
           
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.FAILED, BusinessProfileDetails: action.payload, BusinessProfile_Message: action.error, Flags:action.Flags }
        case BusinessProfileActions.BusinessProfile_Post.SUCCESS:
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.SUCCESS, BusinessProfileDetails: action.payload, BusinessProfile_Message: action.message }

        default:
            return { ...state }
    }
}
