import React, { Component, Fragment } from 'react'
import ReactMarkdown from "react-markdown";
import preval from 'preval.macro'

class TransitPerxHeader extends Component {
    render() {
      const{
        // logo="logo",
        // menu1="About",
        // menu2="FAQs",
        // menu3="Contact",
        TransitPerx
      } = this.props
        return (
            <Fragment>
                <header
            id="header"
            className="site-navbar js-sticky-header site-navbar-target trasit-header"
            role="banner"
          >
         
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6 col-lg-3">
                  <h1 className="mb-0 transit-logo">
                    <a href="/" className="mb-0 img-box">
                      {
                        TransitPerx.RetailerSec1.Text1 !== "" ?
                        <span><ReactMarkdown source={TransitPerx.RetailerSec1.Text1}/></span>
                        :
                        <img
                              src={TransitPerx.RetailerSec1.Image1.url}
                             className="image-logo"
                              alt="not found"
                              width="90px" height="90px"
                            />

                      }
                    </a>
                  </h1>
                </div>

                <div className="col-12 col-md-9 d-none d-lg-block">
             
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <nav
                    className="site-navigation position-relative text-right nav-menu"
                    role="navigation"
                    aria-label="Main"
                  >
                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block transit-links">
                      <li>
                        <a href={window.location.origin+"/#section1"}className="nav-link">
                        {TransitPerx.RetailerSec1.MenuItem1}
                        </a>
                      </li>
                      <li>
                        <a href={window.location.origin+"/#why"} className="nav-link">
                        {TransitPerx.RetailerSec1.MenuItem2}
                        </a>
                      </li>
                      <li>
                        <a href={window.location.origin+"/#FAQs"} className="nav-link">
                        {TransitPerx.RetailerSec1.MenuItem3}
                        </a>
                      </li>
                      <li>
                      <a target="_blank" className="nav-link" rel="noopener noreferrer" href={preval`module.exports = process.env.RETAILER_WEB_URL`}>
                        {TransitPerx.RetailerSec1.MenuItem4}
                        </a>
                      </li>
                      <li className="signup">
                        <a href={window.location.origin+"/#section2"} className="nav-link">
                        {TransitPerx.RetailerSec1.MenuItem5}
                        </a>
                      </li>
                    </ul>
                  </nav>
                  </div>
                </div>

                <div
                  className="col-6 d-inline-block d-lg-none ml-md-0 py-3"
                  //   style="position: relative; top: 3px;"
                >
                  <a
                    href="!#"
                    className="burger site-menu-toggle js-menu-toggle"
                    data-toggle="collapse"
                    data-target="#main-navbar"
                  >
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
         
          </header>
            </Fragment>
        )
    }
}

export default TransitPerxHeader
