import { formatMs } from '@material-ui/core'
import React, { Component } from 'react'
import './ThankYouPage.css'
import {branding} from "../../RefRecordsConstants"
import { BRANDING} from '../../Config/Config'


class ThankYouContact extends Component {
    // componentDidMount(){
    //     // this.scrollToThankyou
    //     setTimeout(() => {
    //         this.props.scrollToThankyou();
    //       }, 500);
         
    // }
    render() {
        const {
            stroke = "#AB3495"
        } = this.props

        return (
            <div id="thank" className="thanks-wrap">
               <div className="container">
                   <div className="row">
                       <div className="col-sm-3"></div>
                       <div className="col-sm-6">
                       <div className="thank-box contact-box">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="none" stroke={BRANDING === branding.TransitPerxBranding ? "#4E24B6":stroke} stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                    <polyline className="path check" fill="none" stroke={BRANDING === branding.TransitPerxBranding ? "#4E24B6":stroke} stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>
                    <div className="thank-txt-box">
                        <h2 className="thanks-title">
                            Your request has been submitted. We will contact you soon.
                        </h2>
                    </div>
                </div>
                       </div>
                       <div className="col-sm-3"></div>
                   </div>
               </div>
            </div>
        )
    }
}

export default ThankYouContact
