import { combineReducers } from 'redux';
import BusinessProfileReducer from "../Components/GetInTouch/BusinessProfileReducer";
import AccountPaymentReducer from "../Components/AccountPayment/AccountPaymentReducer";
import GetCouponTermsReducer from "../Components/BannerType/GetCouponTerms/GetCouponTermsReducer";
import GetFaceValueReducer from "../Components/BannerType/GetFaceValue/GetFaceValueReducer";
import TransitPerxReducer from "../Components/TransitPerx/TransitPerxReducer";
import TimeToCallReducer from "../Components/GetInTouch/TimeToCall/TimeToCallReducer"
import GetStatesReducer from "../Components/GetInTouch/GetStates/GetStatesReducer"
import TermAndConditionsReducer from "../Components/TermAndCondition/TermAndConditionsReducer"
import AppReducer from "../AppReducer"
import CheckDuplicateBusinessReducer from "../Components/GetInTouch/CheckDuplicateBusiness/CheckDuplicateBusinessReducer"
import NjtSignUpFormReducer from "../Components/NjtSignUpForm/NjtSignUpFormReducer"
import BusinessCategoryReducer from "../Components/NjtSignUpForm/BusinessCategory/BusinessCategoryReducer"
import UserAgreementVersionReducer from "../Components/GetInTouch/UserAgreementVersion/UserAgreementVersionReducer"

const reducer = combineReducers({
    BusinessProfileReducer,
    AccountPaymentReducer ,
    GetCouponTermsReducer ,
    GetFaceValueReducer ,
    TransitPerxReducer,
    TimeToCallReducer,
    TermAndConditionsReducer,
    AppReducer,
    CheckDuplicateBusinessReducer,
    NjtSignUpFormReducer,
    BusinessCategoryReducer,
    UserAgreementVersionReducer,
    GetStatesReducer
});

export default reducer;
