import React, { Component, Fragment } from 'react'
import "./TransitPerxWhy.css"

class TransitPerxWhy extends Component {
    render() {
        const {
            TransitPerx
          } = this.props;
        return (
        <Fragment>
            <div className="t-why-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="why-heading">
                            {TransitPerx.RetailerSec4.Header}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="why-img">
                                <img src={TransitPerx.RetailerSec4.RetailerSubsection44.Image4.url} alt="not found" />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="why-txt-box">
                                <div className="why-heading-txt">
                                {TransitPerx.RetailerSec4.RetailerSubsection44.Header}
                                </div>
                                <hr className="line-1" />
                                <div className="why-discribtion">
                                {TransitPerx.RetailerSec4.RetailerSubsection44.Description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="t-why-wrap-2">
                <div className="container">
                <div className="row">
                      <div className="col-sm-5">
                            <div className="why-txt-box">
                                <div className="why-heading-txt">
                                {TransitPerx.RetailerSec4.RetailerSubsection44.Header2}
                                </div>
                                <hr className="line-1" />
                                <div className="why-discribtion">
                                {TransitPerx.RetailerSec4.RetailerSubsection44.Description2}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="why-img">
                                <img src={TransitPerx.RetailerSec4.RetailerSubsection44.Image5.url} alt="not found" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}

export default TransitPerxWhy
