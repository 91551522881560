import React, {Component, Fragment} from 'react';

import {GetStatesStatus} from './GetStatesConstants';
import AutoCompleteInput from "../../Inputs/SelectSearch/AutoCompleteInput";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const stateData2 = [
  {value:'', name: 'Select'}
];

const LoadingDummyData = [
  {value:'', name: 'Loading...'}
];

export class GetStatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GetStates: props.bestStates?props.bestStates:'',
      open:true
    };

    if(props.GetStates_GET_status !== GetStatesStatus.GetStates_GET.LOADING)
    props.getGetStates();
    console.log("In States to Call View")
  }
  closeSnackBar = ()=>{
    this.setState({
      open:false
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
   
    if(nextProps.bestStates !== prevState.GetStates && nextProps.bestStates == ""){
      // alert(nextProps.bestStates)
      return{
        GetStates:nextProps.bestStates
      }
   }
   return null;
 }

  getScreen(status) {
    switch (status) {
      case GetStatesStatus.GetStates_GET.NEW:
        return (
          <Fragment>
            <AutoCompleteInput
                  id={"StateId"}
                  label={"State"}
                  star={"*"}
                  placeholder = {"Select..."}
                  data={this.props.GetStates}
                  inputValue={this.props.inputValue}
                  disableInputChange={true}
                  onChange={this.props.onChange}
                  Flag={this.props.Flag}
                />
          </Fragment>
        );
      case GetStatesStatus.GetStates_GET.SUCCESS:
        return (
          <Fragment>
          <AutoCompleteInput
                  id={"StateId"}
                  label={"State"}
                  star={"*"}
                  placeholder = {"Select..."}
                  data={this.props.GetStates}
                  inputValue={this.props.inputValue}
                  disableInputChange={true}
                  onChange={this.props.onChange}
                  Flag={this.props.Flag}
                />
          </Fragment>
        )
        
      case GetStatesStatus.GetStates_GET.FAILED:
        return (
          <Fragment>
            
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="error">
                {'Failed to get States data'}
              </Alert>
            </Snackbar>
            <AutoCompleteInput
                  id={"StateId"}
                  label={"State"}
                  star={"*"}
                  placeholder = {"Select..."}
                  data={[]}
                  inputValue={this.props.inputValue}
                  disableInputChange={true}
                  onChange={this.props.onChange}
                  Flag={this.props.Flag}
                />
          </Fragment>
        );

      case GetStatesStatus.GetStates_GET.LOADING:
        this.state.open = true
        return <Fragment>
            <AutoCompleteInput
                  id={"StateId"}
                  label={"State"}
                  star={"*"}
                  placeholder = {"Select..."}
                  data={this.props.GetStates}
                  // data={[{"id":1446,"label":"Others","value":"jhb"},{"id":1446,"label":"Others2","value":"2"},{"id":1446,"label":"Others3","value3":""}]}
                  inputValue={this.props.inputValue}
                  disableInputChange={true}
                  onChange={this.props.onChange}
                  Flag={this.props.Flag}
                />
         </Fragment>;
        
        
      default:
        break;
    }
  }
  render() {
    return (
      <Fragment>{this.getScreen(this.props.GetStates_GET_status)}</Fragment>
    );
  }
}

export default GetStatesView;

