import { AccountPaymentStatus, AccountPaymentActions } from './AccountPaymentConstants';

const AccountPayment_initialState = {
    AccountPayment_Status: AccountPaymentStatus.AccountPayment_Post.NEW,
    AccountPayment_Message: '',
    AccountPaymentDetails: {},
}
export default function (state = AccountPayment_initialState, action) {
    switch (action.type) {
        case AccountPaymentActions.AccountPayment_Post.LOADING:
            return { ...state, 
            AccountPayment_Status: AccountPaymentStatus.AccountPayment_Post.LOADING,AccountPayment_Message: '',
            AccountPaymentDetails: {}
         }
        case AccountPaymentActions.AccountPayment_Post.NEW:
            return { ...state, AccountPayment_Status: AccountPaymentStatus.AccountPayment_Post.NEW}

        case AccountPaymentActions.AccountPayment_Post.FAILED:
           // alert("test")
            return { ...state, AccountPayment_Status: AccountPaymentStatus.AccountPayment_Post.FAILED, AccountPaymentDetails: action.payload, AccountPayment_Message: action.error }
        case AccountPaymentActions.AccountPayment_Post.SUCCESS:
            return { ...state, AccountPayment_Status: AccountPaymentStatus.AccountPayment_Post.SUCCESS, AccountPaymentDetails: action.payload, AccountPayment_Message: action.message }

        default:
            return { ...state }
    }
}
