import { GetStatesStatus, GetStatesActions } from './GetStatesConstants';

const GetStates_initialState = {
    GetStates_Status: GetStatesStatus.GetStates_GET.NEW,
    GetStates_Error: '',
    GetStates: [],
}
export default  function  (state = GetStates_initialState, action) {
    switch (action.type) {
        case GetStatesActions.GetStates_GET.LOADING:
            return { ...state, GetStates_Status: GetStatesStatus.GetStates_GET.LOADING }
            case GetStatesActions.GetStates_GET.NEW:
                return { ...state, GetStates_Status: GetStatesStatus.GetStates_GET.NEW }
    
        case GetStatesActions.GetStates_GET.FAILED:
            return { ...state,  GetStates_Status: GetStatesStatus.GetStates_GET.FAILED}
        case GetStatesActions.GetStates_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetStates_Status: GetStatesStatus.GetStates_GET.SUCCESS, GetStates:action.payload}
        
        default:
            return { ...state,
            }
    }
}
