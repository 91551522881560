import React, { Component, Fragment } from 'react'
import LabelInput from '../Inputs/LabelInput'
import InputMask from '../Inputs/MaskInput/MaskInput'
import './ScheduleCall.css'

import TimeToCallContainer from '../GetInTouch/TimeToCall/TimeToCallContainer'
import {ROOT_URL, TRANSIT_COMPANY_ID} from '../../Config/Config'
import Axios from 'axios'
import LabelTextAreaInput from '../Inputs/LabelTextAreaInput'
import moment from 'moment/moment'

class ScheduleCall extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            email: "",
            emailErrorMsg: "Field Required",
            emailFlag: "",
            userName: "",
            userNameErrorMsg: "Field Required",
            userNameFlag: "",
            bestTime: "",
            bestTimeErrorMsg: "Field Required",
            bestTimeFlag: "",
            phone: "",
            phoneErrorMsg: "Field Required",
            phoneFlag: "",
            open: false,
            request: "",
            requestErrorMsg: "Field Required",
            requestFlag: ""
        }
    }



// userNameHandler Function ...................................
  userNameHandler = (e) => {
    const reg = /^[a-zA-Z ]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        userId: "",
        userNameFlag: false,
      });
    } else {
      this.setState({
        userNameFlag: true,
        userNameErrorMsg: "Use only Alphabets",
      });
    }
    this.setState({ userName: e.target.value });
  };

// emailHandler Function ...................................
 emailHandler = (e) => {
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+[.][a-zA-Z]{2,10}$/;

    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        emailId: "",
        emailFlag: false,
      });
    } else {
      this.setState({
        emailFlag: true,
        emailErrorMsg: "Enter Valid Email",
      });
    }
    this.setState({ email: e.target.value });
  };
// phoneHandler Function  ...................................
  phoneHandler = (e) => {
    
    const reg = /[\d]{3}-[\d]{3}-[\d]{4}$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ phoneFlag: false });
    } else {
      this.setState({
        phoneFlag: true,
        phoneErrorMsg: "Enter Valid Phone Number",
      });
    }
    this.setState({ phone: e.target.value });
  };

  bestTimeHandler = (bestTime)=>{
    console.log("Best Time to Call change", bestTime)

      this.setState({
        bestTime: bestTime, bestTimeFlag: false
      });
    
  }


// requestHandler Function ...................................
requestHandler= e => {

  this.setState({ request: e.target.value, requestFlag: false  });
}


// Scroll Function .............................................

scrollToUserName = () => {
  this.userName.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  this.InputUser.focus();
}
scrollToEmail= () => {
  this.emailScroll.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  this.InputEmail.focus();
}
scrollToNumber= () => {
  this.number.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  this.InputPhoneNumber.focus();
}
scrollToBestTime=()=>{
  this.bestTimeCall.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  
  this.SelectBestTimeCall.focus();
}

setUserFocus=(ref)=>{
  this.InputUser  = ref
}

setEmailFocus=(ref)=>{
  this.InputEmail  = ref
}

setPhoneFocus=(ref)=>{
  
  this.InputPhoneNumber  = ref
}

setCallFocus=(ref)=>{

  this.SelectBestTimeCall  = ref
}

  //  submitHandler  .............................................


  submitHandler = () => {
     if (this.state.userNameFlag || this.state.userName === "") {
        this.setState({
          userNameErrorMsg:
            this.state.userNameFlag && this.state.userName.length > 0
              ? this.state.userNameErrorMsg
              : "Field Required",
          userNameFlag: true,
        });
        this.scrollToUserName();
      }  else if (this.state.emailFlag || this.state.email === "") {
        this.setState({
          emailErrorMsg:
            this.state.emailFlag && this.state.email.length > 0
              ? this.state.emailErrorMsg
              : "Field Required",
          emailFlag: true,
        });
        this.scrollToEmail();
      } else if (this.state.phoneFlag || this.state.phone === "") {
        this.setState({
          phoneErrorMsg:
            this.state.phoneFlag && this.state.phone.length > 0
              ? "Enter Valid Phone Number"
              : "Field Required",
          phoneFlag: true,
        });
        this.scrollToNumber();
      } else if (this.state.bestTimeFlag || this.state.bestTime == "") {
        this.setState({
          bestTimeErrorMsg: "Select Best Time To Call",
          bestTimeFlag: true,
        });

        this.scrollToBestTime();
      } 
      else {
        
      
       

        const data = {
          Last_Name:this.state.userName,
          Email_ID:this.state.email,
          Phone_Number:this.state.phone,
          Best_Time_to_Call:this.state.bestTime,
          Request:this.state.request,
          
          Time_Stamp: new Date(),
          User_Type: "Partner",
          Subject:"Partner Submitted Request from Landing Page",
          transit_company_id: parseInt(TRANSIT_COMPANY_ID)
        };

        console.log("Without Error!!!!")
        console.log("contact submit", data)
        //return;

        //return;

        this.props.setZohoLoading(true);
       

        Axios.request({
          method: "POST",
          url: `${ROOT_URL}/api/contact/contact_form_zapier_zoho`,
          headers: { 'Content-Type': 'application/json'},
          data: JSON.stringify(data),
        })
        .then((response)=>{
          
          if (response.status == "200"){
           
            this.props.setZohoLoading(false);
            
            this.props.setShowThankYou(true)

          }
        })
        .catch((error)=>{
          console.log("Error in sending contact lead to Zoho", error)
          this.props.setZohoLoading(false);
          this.props.setShowThankYou(true);
        })
     
      }

        

  };

    render() {
        const {
            email,
            emailErrorMsg,
            emailFlag,
            userName,
            userNameErrorMsg,
            userNameFlag,
            phone,
            phoneErrorMsg,
            phoneFlag,
        
            request,
            requestErrorMsg,
            requestFlag
        } = this.state
        return (
            
            <Fragment>
                <div className="contact-form">                
                <div className="row">
                <div className="col-sm-12">
                    <div className="input-b">
                        <LabelInput
                        type="text"
                        label="Name"
                        id="name"
                        idError="errorName"
                        star="*"
                      
                        name="Username"
                        inputValue={userName}
                        errorMsg={userNameErrorMsg}
                        flag={userNameFlag}
                        maxLength={100}
                        onChange={this.userNameHandler}
                        refrence={this.setUserFocus}
                        />
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-sm-12">
                    <div className="input-b margin">
                        <LabelInput
                         type="email"
                         label="Email"
                         star="*"
                         name="email"
                         id="email"
                         idError="emailError"
                         inputValue={email}
                         errorMsg={emailErrorMsg}
                         flag={emailFlag}
                         onChange={this.emailHandler}
                         refrence={this.setEmailFocus}
                        />
                    </div>
                </div>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.userName = el;
              }}
            ></div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.emailScroll = el;
              }}
            ></div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="input-b margin">
                        <InputMask
                         type="text"
                         label="Phone Number"
                         star="*"
                         name="phone"
                         id="phone-number"
                         idError="phoneError"
                         inputValue={phone}
                         errorMsg={phoneErrorMsg}
                         flag={phoneFlag}
                         onChange={this.phoneHandler}
                         refrence={this.setPhoneFocus}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input-b margin">
                
                <TimeToCallContainer
                    
                    stateFlag={this.state.bestTimeFlag}
                  
                    errorMsg={this.state.bestTimeErrorMsg}
                    bestTimeHandler={this.bestTimeHandler}
                    refrence={this.setCallFocus}
                />

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="input-b margin">
                        <LabelTextAreaInput
                        type="Request"
                      
                        maxLength={2000}
                        label="Request"
                        name="request"
                        inputValue={request}
                        errorMsg={requestErrorMsg}
                        flag={requestFlag}
                        onChange={this.requestHandler}
                        />
                    </div>
                </div>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.number = el;
              }}
            ></div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.bestTimeCall = el;
              }}
            ></div>
            <div className="row">
            <div className="col-sm-12">
                <div className="help-form-btn call-sh margin">
                <button
                  className="help-submit-btn"
                onClick={this.submitHandler}
                >
                  Submit
                </button>
                </div>
            </div>
            </div>
            </div>
            </Fragment>
            
        )
    }
}

export default ScheduleCall
