import React, { Component } from "react";
import TransitPerxSchedule from "../TransitPerxSchedule"
import ThankYouContact from "../../../ThankYouPage/ThankYouContact";
import Loading from "../../../../Utils/Loading";

class NjtContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zohoLoading: false,
      showThankYou: false,
    };
  }

  setZohoLoading = (zohoLoading) => {
    this.setState({
      zohoLoading,
    });
  };

  setShowThankYou = (showThankYou) => {
    this.setState({
      showThankYou,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  resetStates = () =>{

    setTimeout(()=>{
      this.setState({
        zohoLoading: false,
        showThankYou: false,
      })
    }, 4000);
    
  }

  render() {
    return (
      <div className="njt-contact-wrap">
        <div className="njt-conctact-body">
          {
            !(this.state.zohoLoading) ?

              this.state.showThankYou ?
                (<div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <ThankYouContact />
                    </div>
                  </div>
                </div>)
                :
(
  <TransitPerxSchedule
  setShowThankYou={this.setShowThankYou}
  setZohoLoading={this.setZohoLoading}
  resetStates={this.resetStates}
//zohoLoading={this.state.zohoLoading}
/>
)
              :
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="zoho-loader">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
          }



        </div>

      </div>
    );
  }
}

export default NjtContact;
