import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { RETAILER_WEB_URL, RIDER_WEB_URL,LANDING_PAGE_END_BRANDING_IMAGE_URL } from "../../Config/Config"
import {Helmet} from "react-helmet";
import GoogleTranslate from '../../GoogleTranslate/GoogleTranslate';

class NjtHeader extends Component {

  render() {

    return (
      <>
       <Helmet>
      <meta name="keywords" content="NJT Partner" />
    <meta property="og:title" content="NJT Partner" />
    <meta property="og:image:secure_url" content="%PUBLIC_URL%/favicon.png"/>
    <meta property="og:image" content="%PUBLIC_URL%/favicon.png" />
    <meta
      name="description"
      content="New Jersey Transit Reward Program. Travel, Redeem, Repeat."
    />
    </Helmet>
      <header className="njt-landing-header">
      <div className="row">
        <div className="col-sm-12">
          <nav className="navbar navbar-expand-lg fixed-top navbar-light">
            <div className="container" style={{paddingTop:"35px"}}>
              <div className="njt-logo-header">
                <Link className="navbar-brand" to="/">
                  <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Njt-Logo-All.svg"}
                    alt="NJT Rewards® Logo" />
                </Link>
              </div>
          <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <nav
                  className="site-navigation position-relative nav-menu"
                    role="navigation"
                    aria-label="Main"
                  >
                  <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                      <a
                        className="nav-link border"
                        target={"_blank"}
                        href={RIDER_WEB_URL}
                        rel="noopener noreferrer"
                      >
                        Members
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link bg-color"
                        href="/SignUp"
                        rel="noopener noreferrer"
                      >
                        Partner Sign Up
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={RETAILER_WEB_URL}
                        target={"_blank"}
                        className="nav-link border"
                        rel="noopener noreferrer"
                      >
                        Log In
                        </a>
                    </li>
                    <li>
                    <GoogleTranslate cookies={this.props.cookies}/>

                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </nav>
        </div>
      </div>
      
      </header>
      </>
    )
  }
}

export default NjtHeader
