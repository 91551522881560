export const AccountPaymentActions= {
	
	AccountPayment_Post :{
		NEW:"AccountPayment_Post_NEW",
		SUCCESS:"AccountPayment_Post_SUCCESS",
		FAILED:"AccountPayment_Post_FALIURE",
		LOADING:"AccountPayment_Post_LOADING",
	}
}


export const AccountPaymentStatus ={

	AccountPayment_Post :{
		NEW:"AccountPayment_Post_NEW",
		SUCCESS:"AccountPayment_Post_SUCCESS",
		FAILED:"AccountPayment_Post_FALIURE",
		LOADING:"AccountPayment_Post_LOADING",
		
	}

}
