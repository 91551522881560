import PropTypes from 'prop-types'
import React, { Component } from 'react'
import "../NewHomeLayout.css"

export default class SectionThree extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    boldParagraph: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }

  render() {
    const { heading, boldParagraph, paragraph1, paragraph2, image, altText,link,linkText } = this.props;
    
    return (
      <div className='section_two last_section'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-sm-12 col-md-6'>
              <div className='image_box_reach'>
                <img src={image} alt={altText} />
              </div>
            </div>
            <div className='col-sm-12 col-md-6'>
              
                {heading}
              
              <div className='partner_new_btn'>
              <a href={link}
              target="_blank"
                  className="promotion-submit-btn"
                  // onClick={this.props.onClick}
                  // disabled={this.props.acceptButtonDisabled}
                >
                  {linkText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
