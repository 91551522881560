import React, { Component } from "react";
import LabelInput from "../Inputs/LabelInput";
import './RiderTeaserPage.css'
import {LANDING_PAGE_END_BRANDING_IMAGE_URL} from "../../Config/Config"

class RiderTeaserPage extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          email: "",
          emailId: "",
          emailErrorMsg: "Field Required",
          emailFlag: "",
          confirmEmail: "",
          confirmEmailErrorMsg: "",
          confirmEmailFlag: "Field Required",
          formValid:false,
          data:{}
        };

      }
// emailHandler Function ...................................
  emailHandler = (e) => {
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+[.][a-zA-Z]{2,3}$/;
    const validReg = reg.test(e.target.value);
    let flag = false;
    let ErrorMsg = this.state.emailErrorMsg;
    if (validReg) {
      console.log("valid reg expression")
    } else {
      flag = true;
      ErrorMsg =
        "Enter Valid Email";
    }
    this.setState({
        email: e.target.value,
        emailFlag: flag,
        emailErrorMsg: ErrorMsg,
        emailRequired: false,
        confirmEmail: "",
        confirmEmailFlag: false,
        confirmEmailRequired: false,
  
      });
  };
  // confirmPasswordHandler Function ...................................
  confirmEmailHandler = (e) => {
    this.setState(
      {
        confirmEmail: e.target.value,
        confirmEmailFlag: false,
      },
      () => {
        
        if (this.state.confirmEmail !== this.state.email) {
          this.setState({
            confirmEmailFlag: true,
            confirmEmailRequired: true,
            confirmEmailErrorMsg: "Email does not match",
          });
        }
      }
    );
  };

  submitHandler = () => {
    if (this.state.emailFlag || this.state.email === "") {
        this.setState({
          emailErrorMsg:
            this.state.emailFlag && this.state.email.length > 0
              ? this.state.emailErrorMsg
              : "Field Required",
          emailFlag: true,
        });
      } 
      else if (this.state.confirmEmailFlag || this.state.confirmEmail === "") {
         this.setState({
            confirmEmailErrorMsg:
            this.state.confirmEmailFlag && this.state.confirmEmail.length > 0
              ? this.state.confirmEmailErrorMsg
              : "Field Required",
              confirmEmailFlag: true,
        })
      } else {
        FormData={
         Email_ID:this.state.email.toLowerCase(),
         Campaign_Name: "NJT_Customer_1st_Teaser"
        
       }

       console.log("email", FormData)
       this.setState({
         formValid: true,
         data: FormData
       })
       
    }
  

};

  
  render() {
    const {
        email,
        emailErrorMsg,
        emailFlag,
        confirmEmail,
        confirmEmailErrorMsg,
        confirmEmailFlag,
        // formValid,
        // data
      } = this.state;
    return (
      <div className="teaser-box">
          <div className="container-fluid">
              <div className="teaser-bbb">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 bg-color-res">
                <div className="pre-teaser-wrap-right-corner">
                    <div className="pre-teaser-left-121 tabs-teaser-2">
                        <div className="pre-teaser-lg-212">
                            <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"tabs21.png"}
                                className="pre-teaser-log" alt="not found" />
                        </div>
                    </div>
                    <div className="pre-teaser-right121 mobile-tease">
                        <div className="pre-teaser-stars-desk">
                            <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Stars-1.svg"}
                                className="pre-teaser-stars" alt="not found" />
                        </div>
                    </div>
                </div>
                <div className="teaser-col-1">
                  <div className="teaser-inn-b">
                  <div className="teaser-col-box1">
                      <div className="first-heading">
                        Get Ready to Get
                      </div>
                      <div className="headinf-teaser">
                      <div className="heading-two">
                        Rewarded
                      </div>
                      </div>
                  </div>
                  <div className="teaser-form-box">
                      <div className="tearr-box">
                      <div className="teaser-inner-form-des">
                      NJT Rewards will be launching soon!
                      </div>
                      <div className="teaser-inner-form-des margin">
                      Sign up to be one of the first to know when you can join.
                      </div>
                      <div className="teaser-form-field">
                      <div className="rider-f-b">
                            <LabelInput
                                type="email"
                                label="Email Address"
                                star="*"
                                name="email"
                                // id={this.state.emailId}
                                inputValue={email}
                                errorMsg={emailErrorMsg}
                                flag={emailFlag}
                                onChange={this.emailHandler}
                                // disabled={this.state.fieldsDisabled}
                            />
                            </div>
                      </div>
                      <div className="teaser-form-field margin">
                      <div className="rider-f-b">
                            <LabelInput
                                type="email"
                                label="Confirm Email Address"
                                star="*"
                                name="email"
                                // id={this.state.emailId}
                                inputValue={confirmEmail}
                                errorMsg={confirmEmailErrorMsg}
                                flag={confirmEmailFlag}
                                onChange={this.confirmEmailHandler}
                                // disabled={this.state.fieldsDisabled}
                            />
                            </div>
                      </div>
                      <div className="inner-new-design-bb">
                        <div className="teaser-inner-form-new">
                        By clicking on the "Submit" button, you agree to our <a target="_blank" href="/termsofuse">Terms of Use</a> Policy. You may receive email notifications from us and can opt out any time.
                        </div>
                      </div>
                    <div className="btn-teaser">
                    <button
                        className="teaser-form-button"
                        onClick={this.submitHandler}
                     >
                        Submit
                    </button>
                    </div>
                  </div>
                  </div>
                  </div>
              </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 bg-color-res-2">
                <div className="pre-teaser-wrap-right-corner">
                    <div className="pre-teaser-left-121 tabs-teaser">
                        <div className="pre-teaser-stars-desk">
                            <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"tabs21.png"}
                                className="pre-teaser-log" alt="not found" />
                        </div>
                    </div>
                    <div className="pre-teaser-right121 desktop-teaser">
                        <div className="pre-teaser-stars-desk">
                            <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"tabs21.png"}
                                className="pre-teaser-stars" alt="not found" />
                        </div>
                    </div>
                </div>
                <div className="teaser-col-2">
                <div className="logo-teaser-box">
                    <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Logo-Teaser.svg"} className="teaser-log" alt="not found" />
                </div>
              </div> 
                </div>
              </div>
              </div>
          </div>
              
      </div>
    );
  }
}

export default RiderTeaserPage;
