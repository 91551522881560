
//******************Exchange Rate Server Calls******************;

import { NjtSignUpFormActions } from './NjtSignUpFormConstants';
import store from '../../Store/store'
import { ROOT_URL } from '../../Config/Config';
import {failure_messages} from 'Messages'

export const NjtSignUpFormServer = {
  //  getNjtSignUpForm:getNjtSignUpForm,
  getNjtSignUpForm: getNjtSignUpForm,

};

//******************Insert NjtSignUpForms******************;

function getNjtSignUpForm(data, token) {

  fetch(ROOT_URL + '/api/ref_globals/is_rapid_registration_on', {
    method: 'Post',
    mode: 'cors',
    headers: { 
    'authorization':token,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(responseJSON => {

      console.log("NjtSignUpForm Data", responseJSON)

      if (responseJSON.status + '' === "200") {
        // alert(responseJSON.data.PartnerRapidRegistrationOn)
        store.dispatch({ type: NjtSignUpFormActions.NjtSignUpForm_Post.SUCCESS, payload: responseJSON.data });
        return;
      } else {

        store.dispatch({ type: NjtSignUpFormActions.NjtSignUpForm_Post.FAILED,  payload: data, error:responseJSON.error});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: NjtSignUpFormActions.NjtSignUpForm_Post.FAILED,payload: data, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: NjtSignUpFormActions.NjtSignUpForm_Post.FAILED, payload: data, error:failure_messages.unExpectedError });
  })
  return { type: NjtSignUpFormActions.NjtSignUpForm_Post.LOADING }

};

//******************Get NjtSignUpForms******************;


//******************Update NjtSignUpForm******************;
