import React, { Component } from 'react'
import TransitPerxFooter from '../TransitPerxFooter/TransitPerxFooter'
import TransitPerxHeader from '../TransitPerxHeader/TransitPerxHeader'
import TransitPerxSchedule from '../TransitPerxSchedule/TransitPerxSchedule'
import TransitPerxContact from '../TransitPerxSchedule/TransitPerxContact/TransitPerxContact'
import "./TransitPerxPage.css"

class TransitPerxPage extends Component {
    render() {
        return (
            <>
                <TransitPerxHeader/>
                <div className="perx-page-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="perx-illus-mobile">
                                    <img src="https://transitperxbranding.s3.amazonaws.com/Perx-Illustration.svg" alt="not found" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-sm-7">
                                <div className="perx-box-left-side">
                                    <h1 className="perx-heading-one">
                                        ENGAGE & REWARD YOUR LOYAL TRANSIT CUSTOMERS
                                    </h1>
                                    <div className="perx-discribtion">
                                    TransitPerx is a comprehensive SaaS Loyalty and Rewards management engagement platform built exclusively for transit authorities and transport companies.
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="perx-illus-desk">
                                    <img src="https://transitperxbranding.s3.amazonaws.com/Perx-Illustration.svg" alt="not found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="perx-why">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="prex-heading-two">Why TransitPerx?</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="perx-card-box">
                                    <div className="perx-icon-box">
                                        <img src="https://transitperxbranding.s3.amazonaws.com/trasitperx-icon1.svg" alt="not found" />
                                    </div>
                                    <div className="perx-card-describe-box">
                                        <h4 className="perx-card-heading">Improved CX</h4>
                                        <div className="perx-describtion-card">
                                        TransitPerx provides flexibility to curate an optimal experience for your customers.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="perx-card-box">
                                    <div className="perx-icon-box">
                                        <img src="https://transitperxbranding.s3.amazonaws.com/trasitperx-icon2.svg" alt="not found" />
                                    </div>
                                    <div className="perx-card-describe-box">
                                        <h4 className="perx-card-heading">Revenue Opportunites</h4>
                                        <div className="perx-describtion-card">
                                        The platform allows Transit Authorities to build a retail partner network for offers, ad targeting and micro commerce.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="perx-card-box">
                                    <div className="perx-icon-box">
                                        <img src="https://transitperxbranding.s3.amazonaws.com/trasitperx-icon3.svg" alt="not found" />
                                    </div>
                                    <div className="perx-card-describe-box">
                                        <h4 className="perx-card-heading">Drive Your CRM Strategy</h4>
                                        <div className="perx-describtion-card">
                                        Get your users hooked and challenge them to earn their way to the top levels of your plan.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="perx-sction" id="schedule">
                <TransitPerxContact/>
                </section>
                <TransitPerxFooter/>
            </>
        )
    }
}

export default TransitPerxPage
