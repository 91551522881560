import React, {Component, Fragment} from 'react';

import {TimeToCallStatus} from './TimeToCallConstants';
import CustomLabelDropDownInput from "../../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const stateData2 = [
  {value:'', name: 'Select'}
];

const LoadingDummyData = [
  {value:'', name: 'Loading...'}
];

export class TimeToCallView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TimeToCall: props.bestTime?props.bestTime:'',
      open:true
    };

    
    props.getTimeToCall();
    console.log("In Time to Call View")
  }
  setTimeToCall = (e) => {
    // alert(e.target.value)
    this.setState({
      TimeToCall:e.target.value
    })
    this.props.bestTimeHandler(e.target.value)
  };
  closeSnackBar = ()=>{
    this.setState({
      open:false
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
   
    if(nextProps.bestTime !== prevState.TimeToCall && nextProps.bestTime == ""){
      // alert(nextProps.bestTime)
      return{
        TimeToCall:nextProps.bestTime
      }
   }
   return null;
 }

  getScreen(status) {
    switch (status) {
      case TimeToCallStatus.TimeToCall_GET.NEW:
        return (
          <Fragment>
            <CustomLabelDropDownInput
                  // type="text"
                  label="Best Time to Call"
                  name="name"
                  star="*"
                  data={
                    this.props.TimeToCall && this.props.TimeToCall.length > 0
                      ? this.props.TimeToCall
                      : stateData2
                  }
                  firstName="Select"
                  value=""
                  inputValue={this.state.TimeToCall}
                  onChange={this.setTimeToCall}
                  refrence={this.props.refrence}
                  flag={this.props.stateFlag}
                  hideFirst= {true}
                  errorMsg={this.props.errorMsg}

                />
          </Fragment>
        );
      case TimeToCallStatus.TimeToCall_GET.SUCCESS:
       
        return (
          <Fragment>
          <CustomLabelDropDownInput
                  // type="text"
                  label="Best Time to Call"
                  name="name"
                  star="*"
                  data={
                    this.props.TimeToCall && this.props.TimeToCall.length > 0
                      ? this.props.TimeToCall
                      : stateData2
                  }
                  firstName="Select"
                  value=""
                  inputValue={this.state.TimeToCall}
                  onChange={this.setTimeToCall}
                  refrence={this.props.refrence}
                  flag={this.props.stateFlag}
                  hideFirst={true}
                  errorMsg={this.props.errorMsg}
                />
          </Fragment>
        );
      case TimeToCallStatus.TimeToCall_GET.FAILED:
        
        return (
          <Fragment>
            
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="error">
                {'Failed to get time to call data'}
              </Alert>
            </Snackbar>
                  <CustomLabelDropDownInput
                  
                  label="Best Time to Call"
                  name="name"
                  star="*"
                  data={
                    this.props.TimeToCall && this.props.TimeToCall.length > 0
                      ? this.props.TimeToCall
                      : stateData2
                  }
                  firstName="Select"
                  value=""
                  inputValue={this.state.TimeToCall}
                  onChange={this.setTimeToCall}
                  refrence={this.props.refrence}
                  flag={this.props.stateFlag}
                
                  hideFirst={true}
                  errorMsg={this.props.errorMsg}
                />
          </Fragment>
        );

      case TimeToCallStatus.TimeToCall_GET.LOADING:
        this.state.open = true
        return <Fragment>
          <div className="load-select">
          <CustomLabelDropDownInput
                  
                  label="Best Time to Call"
                  name="name"
                  star="*"
                  data={LoadingDummyData}
                  firstName="Select"
                  value=""
                  inputValue={this.state.TimeToCall}
                  onChange={this.setTimeToCall}
                  refrence={this.props.refrence}
                  flag={this.props.stateFlag}
                  hideFirst={true}
                  errorMsg={this.props.errorMsg}
                />
          </div></Fragment>;
        
      default:
        break;
    }
  }
  render() {
    return (
      <Fragment>{this.getScreen(this.props.TimeToCall_GET_status)}</Fragment>
    );
  }
}

export default TimeToCallView;

