export const TransitPerxActions= {
	
	TransitPerx_Post :{
		NEW:"TransitPerx_Post_NEW",
		SUCCESS:"TransitPerx_Post_SUCCESS",
		FAILED:"TransitPerx_Post_FALIURE",
		LOADING:"TransitPerx_Post_LOADING",
	}
}


export const TransitPerxStatus ={

	TransitPerx_Post :{
		NEW:"TransitPerx_Post_NEW",
		SUCCESS:"TransitPerx_Post_SUCCESS",
		FAILED:"TransitPerx_Post_FALIURE",
		LOADING:"TransitPerx_Post_LOADING",
		
	}

}
