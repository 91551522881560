import React, { Component } from "react";

import "./CustomLabelDropDownInput.css";


export class LabelDropDownInput extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      data = [],
      onChange,
      containerStyle,
      inputValue,
      label = "label",
      errorMsg = "errorMsg",
      flag = false,
      // name,
      value = "",
      firstName = value,
      // id = "",
      star,
      disabled,
      hideFirst = true,
      disableFirst = false,
      icon="expand_more",
      refrence,
    } = this.props;
    return (
      <div className="clddi_input_container" style={containerStyle}>
        <label htmlFor="select-option" className="clddi_label">
          {label}<span style={{color: "red"}}>{star}</span>
        </label>
        <div className="clddi_div1">
          <div className="clddi_container">
            <select
              className="clddi_input form-select"
              id="select-option"
              value={inputValue}
              onChange={onChange}
              ref={(el) => {
                if(refrence){
                    refrence(el)
                }
                 }}
                 aria-describedby={flag ? "ErrorSelect":""} 
                 aria-required="true" aria-invalid={ flag ? "true":"false"}  
                 data-rule="select" 
            >
             {!hideFirst? <option value={value} disabled={disableFirst}>{firstName}</option>:null}
              {!disabled ? (
                data.map((item, index) => {
                  return (
                    <option
                      value={item.value}
                      key={ index}
                    >
                      {item.name}
                    </option>
                  );
                })
              ) : (
                <option value={inputValue} key={1}>
                  {inputValue}
                </option>
              )}
            </select>
              <i className="material-icons clddi_icon">{icon}</i>
          </div>
          <div id="ErrorSelect" aria-hidden={flag ? "false":"true"} role="alert" style={{ display: flag ? 'block' : 'none' }}>
          <p
            className="clddi_error_text"
          >
            {errorMsg}
          </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LabelDropDownInput;
