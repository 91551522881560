export const UserAgreementVersionActions= {
	
	UserAgreementVersion_GET :{
		NEW:"UserAgreementVersion_GET_NEW",
		SUCCESS:"UserAgreementVersion_GET_SUCCESS",
		FAILED:"UserAgreementVersion_GET_FALIURE",
		LOADING:"UserAgreementVersion_GET_LOADING",
		UPDATE:"DisableUserAgreementVersion_Update_SUCCESS",
	},
	
}


export const UserAgreementVersionStatus ={

	UserAgreementVersion_GET :{
		NEW:"UserAgreementVersion_GET_NEW",
		SUCCESS:"UserAgreementVersion_GET_SUCCESS",
		FAILED:"UserAgreementVersion_GET_FALIURE",
		LOADING:"UserAgreementVersion_GET_LOADING",
	}

}
