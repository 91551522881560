import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class TransitPerxFooter extends Component {
    render() {
        const {CopyRights="© 2020 TransitPerx, Inc. "} = this.props
        return (
            <div className="t-footer-box">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12">
                            <div className="footer-link">
                            <Link href="/termsofuse" target={"_blank"}>Terms of Use</Link> 
                               
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="t-footer-copy">
                                <div className="t-copy">
                               {CopyRights}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransitPerxFooter
