import React, { Component } from "react";
import zipcodes from "zipcodes";
import LabelInput from "../../Inputs/LabelInput";

class CountryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // zipCode: '',
      // cityZip: '',
      // stateZip: '',
    };
  }
  

  handleZipCodeChange = (event) => {
    const newZipCode = event.target.value;
    // this.setState({ zipCode: newZipCode });
// alert(newZipCode.slice(0,5))
    const locationInfo = zipcodes.lookup(newZipCode.slice(0,5));

    if (locationInfo) {
      this.props.setCityState(locationInfo.city, locationInfo.state)
      // this.props.cityHandler(locationInfo.city)
      // this.props.setCoutryState(locationInfo.state)
      // setCityState(locationInfo.city,  locationInfo.state)
      // this.setState({ cityZip: locationInfo.city, stateZip: locationInfo.state });
    } else {
      this.props.setCityState('', '')
      // this.setState({ cityZip: '', stateZip: '' });
    }
    if(this.props.zipCodeHandler){
      this.props.zipCodeHandler(event)
    }
  };

  handleCityChange = (event) => {
    // this.setState({ cityZip: event.target.value });
    this.props.handleCityChange(event.target.value)
  };

  handleStateChange = (event) => {
    this.props.handleStateChange(event.target.value)
    // this.setState({ stateZip: event.target.value });
  };

  render() {
    // const {  cityZip, stateZip } = this.state;
    const { ZipcodeFlag, ZipcodeErrorMsg, cityFlag, CitycodeErrorMsg, stateFlag, StatecodeErrorMsg, Zipcode , city, state} = this.props;

    return (
      <>
        <div className="col-sm-6">
          <div className="input-b">
            <LabelInput
              type="text"
              star="*"
              label="Zip Code"
              name="zip"
              id="zipCode"
              inputValue={Zipcode}
              errorMsg={ZipcodeErrorMsg}
              flag={ZipcodeFlag}
              onChange={this.handleZipCodeChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="input-b">
            <LabelInput
              type="text"
              star="*"
              label="City"
              name="city"
              id="City"
              inputValue={city}
              errorMsg={CitycodeErrorMsg}
              flag={cityFlag}
              onChange={this.handleCityChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="input-b">
            <LabelInput
              type="text"
              star="*"
              label="State"
              name="state"
              id="State"
              inputValue={state}
              errorMsg={StatecodeErrorMsg}
              flag={stateFlag}
              onChange={this.handleStateChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CountryAddress;
