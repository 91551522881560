import React, {  Fragment, PureComponent } from 'react'
import { AppStatus } from './AppConstants';
import App from "./App"
import Loading from './Utils/Loading';
import { withRouter } from "react-router-dom";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class AppView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading:false,
      showApp:false,

    }
    props.resetReducerState()


    this.props.getApp();

  }


  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  getScreen(status) {
   
    switch (status) {
      case AppStatus.App_GET.NEW:
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%",height: "100vh" }}>
            <Loading/>
          </div>
        );

      
      case AppStatus.App_GET.SUCCESS:

        console.log("In GET SUCCESS of Term and Condition")
        console.log("App data", this.props.App)
    
          return (
            <Fragment >
              <App cookies = {this.props.cookies} App = {this.props.App}  />
            </Fragment>
          );
        
  
      case AppStatus.App_GET.FAILED:
        if(this.state.loading)

      console.log("In GET FAILED of Term and Condition")
        return (
          <Fragment >
            {this.props.App_Msg !=="" && this.props.App_Msg !="Error: Provide Email to Encrypt."?
                <Snackbar
                open={this.state.open}
                autoHideDuration={2000}
                onClose={this.closeSnackBar}
              >
                <Alert onClose={this.closeSnackBar} severity="error">
                  {this.props.App_Msg}
                </Alert>
              </Snackbar>:
              null
            }
            <div className="strapi-failure">
              <h1>The server could not be contacted. This may be a temporary glitch or the server may be down.Please try again in a while</h1>
            </div>
          </Fragment>
        );
     
      case AppStatus.App_GET.LOADING:
            this.state.loading=true
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%",height: "100vh" }}>
            <Loading/>
          </div>
        );
      default:
        return <div />
    }

  }
  render() {
    return (
      <Fragment>
       {this.getScreen(this.props.App_GET_status)}
       </Fragment>
    )
  }
}

export default withRouter(AppView)
