import React, { Component, Fragment } from "react";
import { TransitPerxStatus } from "./TransitPerxConstants";
import Loading from "../../Utils/Loading";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TransitPerx from './TransitPerx'
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class TransitPerx_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success:false
    };
    this.props.getTransitPerx()
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  getScreen(status) {
    switch (status) {
      case TransitPerxStatus.TransitPerx_Post.NEW:
       
        return (
          <Fragment>
              <Loading />
          </Fragment>
        );

      case TransitPerxStatus.TransitPerx_Post.SUCCESS:
        console.log("success:", this.props.TransitPerx)
        if(!this.state.success)
        return (
          <Fragment>
                <TransitPerx  TransitPerx={this.props.TransitPerx} />
          </Fragment>
        );
      
      case TransitPerxStatus.TransitPerx_Post.FAILED:
        // if(this.props.Flags)

        if(!this.state.success)
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="error">
                {this.props.TransitPerx_Message}
              </Alert>
            </Snackbar>
            <Loading />;
          </Fragment>
        );
        
      case TransitPerxStatus.TransitPerx_Post.LOADING:
        this.state.open = true;
        this.state.success = false
        return <Fragment>
            <div className="main-load">
                <Loading />
            </div>
          </Fragment>;
        
      default:
        break;
    }
  }
  render() {
    // alert(this.props.TransitPerx_post_status)
    return this.getScreen(this.props.TransitPerx_post_status);
  }
}

export default TransitPerx_View;
