import React, { Component } from 'react'
import { LANDING_PAGE_END_BRANDING_IMAGE_URL } from '../../Config/Config';
import GetInTouch from '../NewGetInTouch/BusinessProfileContainer'
import '../NjtHomeOne/NjtHomeOne.css'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import heroImage from "../../Assets/Images/bg-image-one.png"
import logo from "../../Assets/Images/logo.png"

class NjtSignUpForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false,
     
    };

    console.log("jhdjhfd",props.NJTLandingPage.BecomePartner.logo.url)
  }
  
    state = {
      showTermAndCondition: false,
      //agreementVersionAccepted: 0
      retailerData:{},
      agreementVersionAccepted:0
    }
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    componentWillUnmount() {

      console.log("unmount component")
    }

    scrollToThankyou = () => {

        this.Thankyou.scrollIntoView({
          behavior: "smooth",
          // block: "end",
          inline: "nearest",
        });
      };

      

    render() {
        return (
            <main className="njt-sign-wrap">
                 <div style={{backgroundImage: `url(${this.props.NJTLandingPage.BecomePartner.bgImage.url})`,}} className='bg-inner-section'>
                  <div className='container'>
                 <div className="row">
                            <div className="col-sm-10">
                                <div className='heading-box-b deskstop'>
                                {<ReactMarkdown 
                                plugins={[gfm]} 
                                source={this.props.NJTLandingPage.BecomePartner.Title} 
                                allowDangerousHtml>
                                </ReactMarkdown>} 
                                </div>
                                <div className='heading-box-b mobile'>
                                {<ReactMarkdown 
                                plugins={[gfm]} 
                                source={this.props.NJTLandingPage.BecomePartner.Title} 
                                allowDangerousHtml>
                                </ReactMarkdown>} 
                                </div>
                            </div>
                            <div className="col-sm-2">
                              <div className='main-business-box'>
                              <div className='business-logo'>
                              <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Njt-Logo-All.svg"} />
                            {/* <img src={this.props.NJTLandingPage.BecomePartner.logo.url}
                                alt="NJT Rewards® Logo" /> */}
                                </div>
                                </div>
                            </div>
                  </div>
                  </div>
                 </div>
                <div className="njt-sign-body become-partner">
                    <div ref={(el) => {this.Thankyou = el;}} className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            {<ReactMarkdown 
                                plugins={[gfm]} 
                                source={this.props.NJTLandingPage.BecomePartner.FormTitle} 
                                allowDangerousHtml>
                                </ReactMarkdown>} 
                            </div>
                        </div>
                    </div>

                    <GetInTouch
                      scrollToThankyou={this.scrollToThankyou}
                      // is_rapid_registration_on={this.props.is_rapid_registration_on}
                    />
                </div>
            </main>
        )
    }
}

export default NjtSignUpForm
