import React, { PureComponent } from "react";
import CreatableSelect, { createFilter } from "react-select";
import "./SelectSearchInput.css";
import MenuList from "./MenuList";

const customStyles = {
  menu: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    height: 50,
  }),
  singleValue: (provided) => {
    return { ...provided };
  },
};

export default class AutoCompleteInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      focus: false,
    };
  }

  handleChange = (newValue, actionMeta) => {
    // alert(JSON.stringify(newValue))
    this.setState({
      // inputValue: newValue.label,
      focus: true,
    });
    this.props.onChange(newValue);
  };
  handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action == "input-change") {
      let flag = true;
      if (this.props.maxLimit && inputValue.length > this.props.maxLimit)
        flag = false;

      if (flag) {
        let e = {
          label: inputValue,
        };
        if(!this.props.disableInputChange)
        this.props.onChange(e);
        this.setState({
          // inputValue :inputValue,
          focus: false,
        });
      }
    }
  };
  render() {
    let items = [
      { value: "1", label: "Chocolate" },
      { value: "2", label: "Strawberry" },
      { value: "3", label: "Vanilla" },
    ];
    const {
      star,
      id,
      disabled = false,
      label,
      inputValue = "",
      containerStyle,
      data = items,
      placeholder = "Select...",
      maxLimit,
      isSearchable = true,
      Flag = false,
      errorText = "Field Required",
    } = this.props;
    if (this.state.focus && inputValue === "") this.state.focus = false;
    return (
      <>
        <div className="li_container" style={containerStyle}>
          <label htmlFor={id} className="li_label">
            {label}
            <span style={{ color: "red" }}>{star}</span>
          </label>
        </div>
        <CreatableSelect
          isDisabled={disabled}
          blurInputOnSelect={true}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            MenuList
            // Option
          }}
          value={!this.state.focus ? inputValue : undefined}
          onFocus={() => {
            if (this.state.focus) {
              this.setState({
                focus: false,
              });
            }
          }}
          closeMenuOnSelect={true}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          placeholder={placeholder}
          openMenuOnClick={true}
          noOptionsMessage={() => null}
          className="select-search-input"
          options={data}
          theme={(theme) => ({
            ...theme,

            colors: {
              ...theme.colors,
              primary25: "#F1F1F1",
              primary: "#AB3495",
              primary50: "#F1F1F1",
            },
          })}
          isSearchable={isSearchable}
          styles={customStyles}
        />
        <div
          id={"errorId"}
          aria-hidden={Flag ? "false" : "true"}
          role="alert"
          style={{ display: Flag ? "block" : "none" }}
        >
          <p
            className="li_error_text"
            style={{ display: Flag ? "block" : "none" }}
          >
            {errorText}
          </p>
        </div>
        {maxLimit && inputValue ? (
          <p
            className="ltai_label"
            style={{ flex: 1, alignSelf: "flex-end", textAlign: "right" }}
          >
            {inputValue.length} / {maxLimit}
          </p>
        ) : null}
      </>
    );
  }
}
