import React, { Component } from 'react'
import './404.css'
import { Link } from '@material-ui/core'
import {LANDING_PAGE_END_BRANDING_IMAGE_URL} from "../../Config/Config"

class ErrorPage extends Component {
    render() {
        return (
            <div className="wrap-404">
                <div className="container">
                <div className="row">
                            <div className="col-sm-12">
                                <div className="box-img-404 mobile-err">
                                <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"404-Image.png"} alt="not found" />
                                </div>
                            </div>
                        </div>
                    <div className="row align-items-center">
                       
                        <div className="col-sm-8">
                            <div className="main-box-404">
                                <div className="errordiv">
                                    <h1 className="four">Oops!</h1>
                                </div>
                                <div className="main-404">
                                    We can’t seem to find the page you’re looking for!
                                </div>
                                <div className="back-link-404">
                                    <Link href="/">Go Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="box-img-404 deskt-err">
                            <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"404-Image.png"} alt="not found" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ErrorPage
