export const TermAndConditionsActions= {
	
	TermAndConditions_GET :{
		NEW:"TermAndConditions_GET_NEW",
		SUCCESS:"TermAndConditions_GET_SUCCESS",
		FAILED:"TermAndConditions_GET_FALIURE",
		LOADING:"TermAndConditions_GET_LOADING",
		NOTACTIVATED:"RIDER_ACCOUNT_NOT_ACTIVATED"
	},
	TermAndConditions_POST :{
		NEW:"TermAndConditions_POST_NEW",
		SUCCESS:"TermAndConditions_POST_SUCCESS",
		FAILED:"TermAndConditions_POST_FALIURE",
		LOADING:"TermAndConditions_POST_LOADING",

	},
}


export const TermAndConditionsStatus ={

	TermAndConditions_GET :{
		NEW:"TermAndConditions_GET_NEW",
		SUCCESS:"TermAndConditions_GET_SUCCESS",
		FAILED:"TermAndConditions_GET_FALIURE",
		LOADING:"TermAndConditions_GET_LOADING",
		NOTACTIVATED:"RIDER_ACCOUNT_ACTIVATED"
	},
	TermAndConditions_POST :{
		NEW:"TermAndConditions_POST_NEW",
		SUCCESS:"TermAndConditions_POST_SUCCESS",
		FAILED:"TermAndConditions_POST_FALIURE",
		LOADING:"TermAndConditions_POST_LOADING",

	},

}
