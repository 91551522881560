import React, { Component } from 'react'
import "./TransitPerxSlider.css"

class TransitPerxSlider extends Component {
    render() {
      const {
        TransitPerx
      } = this.props;
        return (
            <div className="slider-2-wrap">
            <div className="container">
                <div className="row">
                   <div className="col-sm-12">
                       <h2 className="work-p-heading">
                       {TransitPerx.RetailerSec3.Header}
                       </h2>
                   </div>
                </div>
                
                <div className="crousel-box-4">
                   <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ul className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
  </ul>
  <div className="carousel-inner">
  {TransitPerx.RetailerSec3.RetailerSubsection33.map((item, index) => {
              return (
    <div id={item.id} 
    className={index == 0 ? "carousel-item active" : "carousel-item"}
    >
    <div className="row">
      <div className="col-sm-6">
          <div className="slider-img">
            <img src={item.Image3[0].url} alt="not found" />
          </div>
      </div>
      <div className="col-sm-6">
          <div className="slider-txt-box">
              <div className="setup-1">
              {item.Step}
              </div>
              <div className="slider-heading">
              {item.Header}
              </div>
              <div className="slider-descrip">
              {item.Description}
              </div>
          </div>
      </div>
      </div>
      
    </div>
      
    );
  })}

    
  </div>
  {/* <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a> */}
</div>
                </div>
            </div>
           </div>
        )
    }
}

export default TransitPerxSlider
