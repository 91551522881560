import React from "react";
import InputMask from "react-input-mask";

class MaskInput extends React.Component {
  state = {
    maskValue: ""
  };
  render() {
    const {
      label = "label",
      type = "text",
      inputValue,
      placeholder,
      name = "name",
      onChange,
      errorMsg = "errorMsg",
      flag = false,
      containerStyle,
      readOnly = false,
      maxLength,
      disabled,
      inputStyle,
      star,
      refrence,
      id,
      idError
    } = this.props;
    return (
      <div className="li_container" style={containerStyle}>
        <label htmlFor={id} className="li_label">
          {label}<span style={{color: "red"}}>{star}</span>
        </label>
        <div className="li_input_container">
          <InputMask
          inputRef={(el) => {
            if(refrence){
                refrence(el)
            }
             }}
            className="li_input form-select"
            id={id}
            style={inputStyle}
            type={type}
            placeholder={placeholder}
            name={name}
            value={inputValue}
            onChange={onChange}
            readOnly={readOnly}
            maxLength={maxLength}
            disabled={disabled}
            mask="999-999-999999999999"
            maskChar={null}
            aria-describedby={flag ? idError:""} 
            aria-required="true" 
            aria-invalid={ flag ? "true":"false"}  
            data-rule={type}
          />
      <div id={idError} aria-hidden={flag ? "false":"true"} role="alert" style={{ display: flag ? 'block' : 'none' }}>
          <p
            className="li_error_text"
          >
            {errorMsg}
          </p>
          </div>
        </div>
      </div>
    );
  }
}
export default MaskInput;
