export const FaceValueActions= {
	
	GetFaceValue_GET :{
		NEW:"GetFaceValue_GET_NEW",
		SUCCESS:"GetFaceValue_GET_SUCCESS",
		FAILED:"GetFaceValue_GET_FALIURE",
		LOADING:"GetFaceValue_GET_LOADING",
		UPDATE:"DisableGetFaceValue_Update_SUCCESS",
	},
	
}


export const GetFaceValueStatus ={

	GetFaceValue_GET :{
		NEW:"GetFaceValue_GET_NEW",
		SUCCESS:"GetFaceValue_GET_SUCCESS",
		FAILED:"GetFaceValue_GET_FALIURE",
		LOADING:"GetFaceValue_GET_LOADING",
	}

}
