
//******************Exchange Rate Server Calls******************;

import { BusinessProfileActions } from './BusinessProfileConstants';
import store from '../../Store/store'
import {  ROOT_URL } from '../../Config/Config';
import {failure_messages} from 'Messages'

export const BusinessProfileServer = {
  submitBusinessApplication:submitBusinessApplication,
  saveBusinessProfile: saveBusinessProfile,
  saveRetailerData: saveRetailerData
};

//******************Insert BusinessProfiles******************;
function uploadCouponImage(data) {

  let couponData = {
    fileimg:data.couponFile,
    img_name:data.business_name,
    img_ext:'png',
    isBase64:true,
  }
  delete data.couponFile
  data={...data,CouponImageData:couponData}
  saveBusinessProfile(data)

}
function uploadBannerImage(data){
  
  let bannerData = {
    fileimg:data.bannerFile,
    img_name:data.business_name,
    img_ext:'png',
    isBase64:true,
  }
  delete data.bannerFile
  data={...data,BannerImageData:bannerData}
  uploadCouponImage(data)
}

function submitBusinessApplication(data) {
  let Flags=false
  fetch(ROOT_URL + '/api/business/submitBusinessApplication', {
    method: 'Post',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status + "" === "200") {
        
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: data, message:response.message });
        return;}
      else {

        if(response.Flags)
        Flags = response.Flags

        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,  payload: data, error:response.error, Flags:Flags});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,payload: data, error:failure_messages.unExpectedError, Flags:Flags });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, payload: data, error:failure_messages.unExpectedError,Flags:Flags });
  })

  return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }

}
function saveBusinessProfile(data) {
  if(data.bannerFile)
  {
    console.log("couponFilecouponFile", data)
   

    uploadBannerImage(data)
  }
  else
{  let Flags=false


  fetch(ROOT_URL + '/api/retailer/rapidlyRegisterRetailer', {
    method: 'Post',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status + "" === "200") {
        
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: data, message:response.message });
        }
      else {

        if(response.Flags)
        Flags = response.Flags

        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,  payload: data, error:response.error, Flags:Flags});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,payload: data, error:failure_messages.unExpectedError, Flags:Flags });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, payload: data, error:failure_messages.unExpectedError,Flags:Flags });
  })

  }


  return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }
};


function saveRetailerData(data) {
  
  let Flags=false
  fetch(ROOT_URL + '/api/retailer/RegisterRetailer', {
    method: 'Post',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      // alert(JSON.stringify(response))
      
      if (response.status + "" === "200") {
        
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: data, message:response.message });
        return;
      }
      else {

        if(response.Flags)
        Flags = response.Flags

        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,  payload: data, error:response.error, Flags:Flags});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED,payload: data, error:failure_messages.unExpectedError, Flags:Flags });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, payload: data, error:failure_messages.unExpectedError,Flags:Flags });
  })

  return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }
};