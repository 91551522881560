import { connect } from "react-redux";
import TransitPerxView from "./TransitPerx_View";
import {TransitPerxServer} from "./server"
import {TransitPerxActions} from './TransitPerxConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        TransitPerx_post_status:state.TransitPerxReducer.TransitPerx_Status,
        TransitPerx_Message:state.TransitPerxReducer.TransitPerx_Message,
        TransitPerx:state.TransitPerxReducer.TransitPerxDetails,
        Flags:state.TransitPerxReducer.Flags,
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateBusinessProfile: (data, bussinessId) => {
        //     dispatch( BusinessProfileServer.updateBusinessProfile(data, bussinessId) )
        //  },

        getTransitPerx: () => {

           dispatch( TransitPerxServer.getTransitPerx() )
        },
        resetReducerState: () => {
            dispatch({type:TransitPerxActions.TransitPerx_Post.NEW})
         },
        
        //  GotoContract: () => {
        //     dispatch({type:BusinessProfileActions.BusinessProfile_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const TransitPerxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransitPerxView)

export default TransitPerxContainer;