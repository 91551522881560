import React, { Component, Fragment } from 'react'
class RiderHeader extends Component {
    render() {
        return (
            <Fragment>
                <header
            id="header"
            className="site-navbar js-sticky-header site-navbar-target trasit-header"
            role="banner"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6 col-lg-3">
                  <h1 className="mb-0 transit-logo">
                    <a href="/" className="mb-0">
                      <span>TransitPerx</span>
                    </a>
                  </h1>
                </div>

                <div className="col-12 col-md-9 d-none d-lg-block">
                  <nav
                    className="site-navigation position-relative text-right nav-menu"
                    role="navigation"
                    aria-label="Main"
                  >
                    <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block transit-links">
                      {/* <li className="active">
                        <a href="#section1" className="nav-link">
                          How It Works
                        </a>
                      </li> */}
                      <li>
                        <a href="!#" className="nav-link">
                        About
                        </a>
                      </li>
                      <li>
                        <a href="#FAQs" className="nav-link">
                          FAQs
                        </a>
                      </li>
                      <li>
                        <a href="#section2" className="nav-link">
                        Contact
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div
                  className="col-6 d-inline-block d-lg-none ml-md-0 py-3"
                  //   style="position: relative; top: 3px;"
                >
                  <a
                    href="!#"
                    className="burger site-menu-toggle js-menu-toggle"
                    data-toggle="collapse"
                    data-target="#main-navbar"
                  >
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </header>
            </Fragment>
        )
    }
}

export default RiderHeader
