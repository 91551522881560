import React, { Component, Fragment } from "react";

import styles from "./DropDownInput.css";

const data1 = [
  { value: "", name: "" },
  { value: "value", name: "name" },
  { value: "sar_connor", name: "john_connor sasdsdsd" },
  { value: "john_connor", name: "john_connor" },
  { value: "john_connor", name: "john_connor" }
];
export class DropDownInput extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      data = [],
      onChange,
      containerStyle,
      inputValue,
      label = "label",
      errorMsg = "errorMsg",
      flag = false,
      value = "",
      firstName = value,
      id = "",
      star,
      disabled,
      hideFirst = false,
      disableFirst = false, 
      refrence,
    } = this.props;
    return (
      // <div className="clddi_input_container" style={containerStyle}>
      //   <div className="clddi_div1">
      //     <div className="clddi_container">
            <select
              className={`${styles.clddi_input} form-select`}
              style={{height:34}}
              value={inputValue}
              onChange={onChange}
              ref={(el) => {
                
                if(refrence){
                    refrence(el)
                }
                 }}
                 aria-describedby={flag ? "ErrorMessage":""} 
                 aria-required="true" aria-invalid={ flag ? "true":"false"}  
                 data-rule="select" 
            >
              {!disabled ? (
                data.map((item, index) => {
                  return (
                    <option
                      value={item.value}
                      key={ index}
                    >
                      {item.name}
                    </option>
                  );
                })
              ) : (
                null
                // <option value={inputValue} key={1}>
                //   {inputValue}
                // </option>
              )}
            </select>
              // {/* <i className="material-icons clddi_icon">{icon}</i> */}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default DropDownInput;
