import React from 'react'

import { BRANDING } from '../Config/Config'


//STEP 1:
//create components using React.lazy
const NJTBranding = React.lazy(() => import('./NJTBranding'));
const TransitPerxBranding = React.lazy(() => import('./TransitPerxBranding'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeColor = ({ children }) => {
//   const CHOSEN_THEME = localStorage.getItem('BRANDING') || BRANDING.DEFAULT;
// const CHOSEN_THEME = localStorage.getItem('TYPE_OF_THEME') || TYPE_OF_THEME.DEFAULT;
//   console.log("branding defualt", localStorage)
  console.log("branding", BRANDING)
  console.log("type of branding", typeof(BRANDING))
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(BRANDING=="1") && <NJTBranding />}
        {(BRANDING=="2") && <TransitPerxBranding />}
      </React.Suspense>
      {children}
    </>
  )
}

export default ThemeColor
