import React, { Component } from 'react'
import RiderHeader from '../RiderHeader/RiderHeader'
import RiderSectionOne from '../RiderSectionOne/RiderSectionOne'

export class RiderHome extends Component {
    render() {
        return (
            <div className="rider-wrap">
                <div className="rider-wrap2">
                <div className="site-mobile-menu site-navbar-target">
            <div className="site-mobile-menu-header">
              <div className="site-mobile-menu-close mt-3">
                <span className="icofont-close js-menu-toggle"></span>
              </div>
            </div>
            <div className="site-mobile-menu-body">
              <ul className="site-nav-wrap">
                {/* <li className="active">
                  <a href="#section1" className="nav-link">
                    HOW IT WORKS
                  </a>
                </li> */}
                <li>
                  <a href="!#" className="nav-link">
                  About
                  </a>
                </li>
                <li>
                  <a href="#FAQs" className="nav-link">
                    FAQs
                  </a>
                </li>
                <li>
                <a href="#section2" className="nav-link">
                  Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <RiderHeader/>
          <main id="main">
              <RiderSectionOne/>
          </main>
                </div>
            </div>
        )
    }
}

export default RiderHome
