export const TimeToCallActions= {
	
	TimeToCall_GET :{
		NEW:"TimeToCall_GET_NEW",
		SUCCESS:"TimeToCall_GET_SUCCESS",
		FAILED:"TimeToCall_GET_FALIURE",
		LOADING:"TimeToCall_GET_LOADING",
		UPDATE:"DisableTimeToCall_Update_SUCCESS",
	},
	
}


export const TimeToCallStatus ={

	TimeToCall_GET :{
		NEW:"TimeToCall_GET_NEW",
		SUCCESS:"TimeToCall_GET_SUCCESS",
		FAILED:"TimeToCall_GET_FALIURE",
		LOADING:"TimeToCall_GET_LOADING",
	}

}
