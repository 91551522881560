import React, { PureComponent, Fragment } from "react";
import "../MainStyles.css";
import LabelInput from "../Inputs/LabelInput";
import BUSINESSPROFILE_VIEW from "./BusinessProfile_View";
import Axios from "axios";
import { ROOT_URL } from "../../Config/Config";
import AutoCompleteInput from "../Inputs/SelectSearch/AutoCompleteInput";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import ThankYouModal from "../ThankYouPage/ThankYouModal";
import ZipAddress from "./ZipToAutoSelect/ZipAddress";
import { InlineWidget } from "react-calendly";

const data2 = [
  { value: "phone", label: "Phone Number" },
  { value: "email", label: "Email" },
];
let mountInit = true 
class GetInTouch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      businessId: "",
      businessNameErrorMsg: "Field Required",
      businessNameFlag: "",
      businessAddress: "",
      businessURL: "",
      businessURLErrorMSG: "Field Required",
      businessURLFlag: false,
      businessAddressErrorMsg: "Field Required",
      businessAddressFlag: "",
      businessAddress2: "",
      businessAddressErrorMsg2: "Field Required",
      businessAddressFlag2: "",
      businessCategory: "",
      businessCategoryErrorMsg: "Field Required",
      businessCategoryFlag: "",
      businessCategoryForApplication: "",
      country: "",
      countryFlag: false,
      countryErrorMsg: "Field Required",
      state: "",
      stateFlag: false,
      stateErrorMsg: "Field Required",
      city: "",
      cityFlag: false,
      cityErrorMsg: "Field Required",
      slectMethodflag: false,
      zip5: "",
      zip5ErrorMsg: "Field Required",
      zip5Flag: false,
      zip4: "",
      zip4ErrorMsg: "Field Required",
      zip4Flag: "",
      contactName: "",
      contactNameErrorMsg: "Field Required",
      contactNameFlag: "",
      email: "",
      emailId: "email",
      emailErrorMsg: "Field Required",
      emailFlag: "",
      userName: "",
      userId: "User-name",
      userNameErrorMsg: "Field Required",
      userNameFlag: "",
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: "",
      confirmPassword: "",
      confirmPasswordErrorMsg: "Field Required",
      confirmPasswordFlag: "",
      phone: "",
      phoneErrorMsg: "Field Required",
      phoneFlag: false,
      instructions: "",
      instructionsErrorMsg: "Field Required",
      instructionsFlag: "",
      bestTime: "",
      bestTimeErrorMsg: "Field Required",
      bestTimeFlag: "",
      retailerTerms: null,
      coupon_face_value: "",
      coupon_face_value_flag: false,
      showAccount: false,
      bannerType: "#AB3395",
      showBanner: false,
      success: false,
      countryFocus: false,
      hideAddress: false,
      stateFocus: false,
      hideState: false,
      phoneFocus: false,
      hidePhone: false,
      couponFile: "",
      bannerFile: "",
      maxCoupons: "",
      showclasss: false,
      check1: false,
      check2: false,
      check3: false,
      checkBoxFlag: false,
      label: true,
      disableFields: false,
      captchaVerfMsg: false,
      Allow_Rapid_Coupon_Generation: false,
      checkDuplicateBusiness: false,
      acceptButtonDisabled: false,
      agreement_version_accepted: 0,
      termAndConditionChecked: true,
      termAndConditionCheckedFlag: false,
      termAndConditionCheckedError: "Field Required",
      description: "",
      descriptionFlag: "",
      descriptionError: "",
      showThanksModal: false,
      selectCountry: 'us',
      isLoading: false,
      countryName: "",
      countryFlag: false,
      countryError: "Field Required",
    };
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handleShowThanksModal = (showThanksModal)=>{
    
    this.setState({
      showThanksModal: showThanksModal,
      success: false
    })

  }


 

  disableFields = (disable) => {
    this.setState({
      disableFields: disable,
    });
  };
  showSuccess = () => {
    this.setState({
      success: true,
      showThanksModal: true
    });
  };
  scrollToBusinessName = () => {
    this.businessName.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    this.InputUser.focus();
  };
 
 
  
 
  setUserFocus = (ref) => {
    this.InputUser = ref;
  };
  setEmailFocus = (ref) => {
    this.InputEmail = ref;
  };

  initializeState = () => {
    this.setState({
      businessName: "",
      businessNameErrorMsg: "Field Required",
      businessNameFlag: false,
      businessAddress: "",
      businessAddressErrorMsg: "Field Required",
      businessAddressFlag: "",
      businessAddress2: "",
      businessAddressErrorMsg2: "Field Required",
      businessAddressFlag2: "",
      city: "",
      cityFlag: false,
      cityErrorMsg: "Field Required",
      state: "",
      stateFlag: false,
      zip5: "",
      zip5ErrorMsg: "Field Required",
      zip5Flag: false,
      zip4: "",
      zip4ErrorMsg: "Field Required",
      zip4Flag: "",
      contactName: "",
      contactNameErrorMsg: "Field Required",
      contactNameFlag: "",
      email: "",
      emailErrorMsg: "Field Required",
      emailFlag: "",
      userName: "",
      userNameErrorMsg: "Field Required",
      userNameFlag: "",
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: "",
      confirmPassword: "",
      confirmPasswordErrorMsg: "Field Required",
      confirmPasswordFlag: "",
      phone: "",
      phoneErrorMsg: "Field Required",
      phoneFlag: "",
      instructions: "",
      instructionsErrorMsg: "Field Required",
      instructionsFlag: "",
      bestTime: "",
      bestTimeErrorMsg: "Field Required",
      bestTimeFlag: "",
      bannerType: "#AB3395",
      showBanner: false,
      retailerTerms: "",
      coupon_face_value: "",
      firstName: "",
      lastName: "",
      isLoading: false,
      slectMethod: {},
      description: "",
      descriptionFlag: false,
      descriptionError: "",
    });
  };

  componentDidMount() {
    if(mountInit)
    {
      mountInit = false
      this.getMinCouponCreateLimit();

    }
  }

  getMinCouponCreateLimit = async () => {
    let Allow_Rapid_Coupon_Generation =
      this.state.Allow_Rapid_Coupon_Generation;

    let res_api = await Axios.post(
      ROOT_URL + "/api/ref_globals/getMinCouponCreateLimit",
      {}
    );
    // alert(JSON.stringify(res_api.data.global_value))
    if (res_api && res_api.data && res_api.data.status == "200") {
      try {
        Allow_Rapid_Coupon_Generation = JSON.parse(
          res_api.data.data.Allow_Rapid_Coupon_Generation.toLowerCase().trim()
        );
      } catch (e) {
        console.log(e);
      }
      // alert(res_api.data.data.coupon_create_limit_min)
      this.setState({
        maxCoupons: res_api.data.data.coupon_create_limit_min,
        Allow_Rapid_Coupon_Generation: Allow_Rapid_Coupon_Generation,
      });
    } else {
      this.setState({
        maxCoupons: "",
      });
    }
  };
  // businessNameHandler Function ...................................
  businessNameHandler = (e) => {
    let name = e.target.value.trimLeft();
    console.log(name);

    const reg = /^[a-zA-Z0-9_ `',&.-]*$/;
    const validReg = reg.test(name);
    if (validReg) {
      if (name.length > 0) {
        this.setState({
          businessId: "",
          businessNameFlag: false,
          showclasss: true,
          couponFile: "",
          bannerFile: "",
          check1: false,
          check2: false,
          check3: false,
        });
      } else {
        this.setState({
          showclasss: false,
        });
      }
    } else {
      this.setState({
        businessNameFlag: true,
        businessNameErrorMsg:
          "Use only Alphabets, numeric , hyphen , dash, comma, ampersand, period and apostrophe",
      });
    }

    this.setState(
      { businessName: name.charAt(0).toUpperCase() + name.substring(1) },
      () => {
        if (this.state.businessName.trim() === "") {
          this.setState({
            showBanner: false,
          });
        }
      }
    );
  };

 // contactNameHandler Function ...................................
  contactNameHandler = (e) => {
    let name = e.target.value.trimLeft();
    console.log(name);

    const reg = /^[a-zA-Z0-9_ `',&.-]*$/;
    const validReg = reg.test(name);
    if (validReg) {
      if (name.length > 0) {
        this.setState({
          businessId: "",
          contactNameFlag: false,
          showclasss: true,
          couponFile: "",
          bannerFile: "",
          check1: false,
          check2: false,
          check3: false,
        });
      } else {
        this.setState({
          showclasss: false,
        });
      }
    } else {
      this.setState({
        contactNameFlag: true,
        contactNameErrorMsg:
          "Use only Alphabets, numeric , hyphen , dash, comma, ampersand, period and apostrophe",
      });
    }

    this.setState(
      { contactName: name.charAt(0).toUpperCase() + name.substring(1) },
      () => {
        if (this.state.contactName.trim() === "") {
          this.setState({
            showBanner: false,
          });
        }
      }
    );
  };


  clearPassword = () => {
    this.setState({
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: "",
      confirmPassword: "",
      confirmPasswordErrorMsg: "Field Required",
      confirmPasswordFlag: "",
    });
  };
  setSlectMethod = (e) => {
    this.setState({ slectMethod: e, slectMethodflag: false });
  };

  setBusinessRef = (ref) => {
    this.businessName = ref;
  };
  // phoneHandler Function  ...................................
  phoneHandler = (e) => {
    let reg =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if(e[0] !== '1')
    reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,8}$/im;
    // alert(e)
    const validReg = reg.test(e);
    if (validReg) {
      this.setState({ phoneFlag: false });
    } else {
      this.setState({
        phoneFlag: true,
        phoneErrorMsg: "Enter Valid Phone Number",
      });
    }
    this.setState({ phone: e });
  };
  // emailHandler Function ...................................
  emailHandler = (e) => {
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]{2,10}$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        emailId: "email",
        emailFlag: false,
      });
    } else {
      this.setState({
        emailFlag: true,
        emailErrorMsg: "Enter Valid Email",
      });
    }
    this.setState({ email: e.target.value });
  };
  // city onChange Function ...................

  cityHandler = (e) => {
    let city = e.target.value;
    //  alert(e.target.value)
    const reg = /^[a-zA-Z -]*$/;
    const validReg = reg.test(city);
    if (validReg) {
      this.setState({ cityFlag: false });
    } else {
      this.setState({
        cityFlag: true,
        cityErrorMsg: "Use only Alphabets",
      });
    }
    this.setState({
      city: city.charAt(0).toUpperCase() + city.substring(1),
    });
  };

  setEmailFlag = () => {
    this.setState({
      emailErrorMsg: "Email already exists",
      emailFlag: true,
      emailId: "duplicate",
    });
  };
  setBusinessFlag = () => {
    this.setState({
      businessId: "duplicate",
      businessNameErrorMsg: "Business Name already exists",
      businessNameFlag: true,
    });
    this.scrollToBusinessName();
  };
  setUserNameFlag = () => {
    this.setState({
      userId: "duplicate",
      userNameErrorMsg: "Username already exists",
      userNameFlag: true,
    });
  };

    // todo: State onChange Function ...................

    stateHandler = (e) => {
      if (this.state.state !== "" || this.state.state !== "selectState") {
        this.setState({ stateFlag: false });
      }
      this.setState({ state: e });
    };
    // todo: Zip onChange Function ...................
  
    zipHandler = (e) => {
      //  alert(e.target.value)
      this.setState({ zip5: e, zip5Flag: false });
    };
    zip5Handler = (e) => {
      // alert("kjdn")
      var input = e.target.value;
      var reg = "";
      if (input.length > 5) {
        reg = /[\d]{5}-[\d]{4}$/;
      } else {
        reg = /[\d]{5}$/;
      }
      const validReg = reg.test(input);
      if (validReg) {
        this.setState({ zip5Flag: false });
      } else {
        // alert("test")
        this.setState({
          zip5Flag: true,
          zip5ErrorMsg: "Enter 5 or 9 digit zip code",
        });
      }
      if (input.length > 5 && !input.includes("-")) {
        input = input.substring(0, 5) + "-" + input.slice(5);
      }
      this.setState({ zip5: input });
    };
    //Country Handler
    setCoutry = (e) => {
      // alert(JSON.stringify(e))
      let val = e;
      if (val) {
        this.setState({
          countryName: val,
          countryFlag: false,
        });
      } else {
        this.setState({
          countryName: "",
          countryCode: "",
        });
      }
    };

  submitHandler = () => {
    if (this.state.businessNameFlag || this.state.businessName === "") {
      this.setState({
        businessNameErrorMsg:
          this.state.businessNameFlag && this.state.businessName.length > 0
            ? this.state.businessNameErrorMsg
            : "Field Required",
        businessNameFlag: true,
      });
    } else if (this.state.contactNameFlag || this.state.contactName === "") {
      this.setState({
        contactNameErrorMsg:
          this.state.contactNameFlag && this.state.contactName.length > 0
            ? this.state.contactNameErrorMsg
            : "Field Required",
            contactNameFlag: true,
      });
    } else if (this.state.phoneFlag || this.state.phone === ""){
      this.setState({
        phoneErrorMsg:
          this.state.phoneFlag && this.state.phone.trim().length > 0
            ? this.state.phoneErrorMsg
            : "Field Required",
        phoneFlag: true,
      });
    } else if (this.state.emailFlag || this.state.email === ""){
      this.setState({
        emailErrorMsg:
          this.state.emailFlag && this.state.email.length > 0
            ? this.state.emailErrorMsg
            : "Field Required",
        emailFlag: true,
      });
    } else if (
      this.state.zip5Flag ||
      this.state.zip5 === ""
      || this.state.zip5 == null
    ) {
      this.setState({
        zip5ErrorMsg:
          this.state.zip5Flag || this.state.zip5.length > 0
            ? "Enter 5 or 9 digit zip code"
            : "Field Required",
        zip5Flag: true,
      });
    
    } else if (this.state.cityFlag || this.state.city === ""){
      this.setState({
        cityErrorMsg:
          this.state.cityFlag && this.state.city.length > 0
            ? this.state.cityErrorMsg
            : "Field Required",
          cityFlag: true,
      });
    } else if (this.state.stateFlag || this.state.state === ""){
      this.setState({
        stateErrorMsg:
          this.state.stateFlag && this.state.state.length > 0
            ? this.state.stateErrorMsg
            : "Field Required",
          stateFlag: true,
      });
    }
    else {
    
      let data = {
        business_name:this.state.businessName.trim(),
        email_address:this.state.email.trim(),
        point_of_contact:this.state.contactName.trim(),
        phone:this.state.phone,
        business_state:this.state.state.trim(),
        business_city:this.state.city.trim(),
        zip4:this.state.zip5.includes('-')?this.state.zip5.split("-")[1]:null,
        zip5:this.state.zip5.includes('-')?this.state.zip5.split("-")[0]:this.state.zip5
      }
// alert(JSON.stringify(data))
      this.props.submitBusinessApplication(data)
    }
  };

  stateHandler = (state) => {
    // alert(state)
    this.setState({
      state: state,
      stateFlag: false,
    });
  };


  render() {
    const {
      businessName,
      businessNameErrorMsg,
      businessNameFlag,
      contactName,
      contactNameErrorMsg,
      contactNameFlag,
      zip5,
      zip5ErrorMsg,
      zip5Flag,
      city,
      cityErrorMsg,
      cityFlag,
      email,
      emailErrorMsg,
      emailFlag,
      phoneFocus,
      phoneFlag,
      phoneErrorMsg,
      phone,
      state,
      stateErrorMsg,
      stateFlag,
      showThanksModal,
    } = this.state;

    return (
      <Fragment>
        <div className="get-box">
          <div  ref={this.setBusinessRef}
                      className="container">
            <div className="row"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-b">
                  <LabelInput
                    type="text"
                    star="*"
                    id={this.state.businessId}
                    maxLength={50}
                    idError="businessNameError"
                    label="Name of Business"
                    name="businessName"
                    inputValue={businessName}
                    errorMsg={businessNameErrorMsg}
                    flag={businessNameFlag}
                    onChange={this.businessNameHandler}
                    disabled={this.state.disableFields}
                    refrence={this.setUserFocus}
                  />
                </div>
              </div>
            </div>
              <div className="row">
              <div className="col-sm-6">
              <div className="input-b">
                  <LabelInput
                    type="text"
                    star="*"
                    id={this.state.businessId}
                    maxLength={50}
                    idError="contactNameError"
                    label="Contact Name"
                    name="businessName"
                    inputValue={contactName}
                    errorMsg={contactNameErrorMsg}
                    flag={contactNameFlag}
                    onChange={this.contactNameHandler}
                    disabled={this.state.disableFields}
                    refrence={this.setUserFocus}
                  />
                </div>
              </div>
                <div className="col-sm-6">
                  <div className="input-b">
                    <div className="li_container">
                      <label htmlFor={"PhoneId"} className="li_label">
                        {"Phone"}
                        <span style={{ color: "red" }}>{"*"}</span>
                      </label>
                      <div className="li_input_container">
                        <PhoneInput
                          specialLabel=""
                          inputValue={phone}
                          country={this.state.selectCountry?.toLowerCase()}
                          onChange={this.phoneHandler}
                          placeholder={"Phone Number"}
                          autoFormat={false}
                          containerClass="phoneNumber"
                          inputClass="phoneNumberInput"
                          buttonClass="phoneButton"
                          dropdownClass="phoneDropdown"
                          aria-describedby={phoneFlag ? "phoneNumber" : ""}
                          aria-required="true"
                          aria-invalid={phoneFlag ? "true" : "false"}
                          data-rule={phone}
                          ref={(el) => (this.businessPhone = el)}
                          inputProps={{ autoFocus: phoneFocus }}
                        />
                        <div
                          id={"errorId"}
                          aria-hidden={phoneFlag ? "false" : "true"}
                          role="alert"
                          style={{ display: phoneFlag ? "block" : "none" }}
                        >
                          <p
                            className="li_error_text"
                            style={{ display: phoneFlag ? "block" : "none" }}
                          >
                            {phoneErrorMsg}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-b">
                    <LabelInput
                      idError="emailError"
                      type="email"
                      label="Email Address"
                      star="*"
                      name="email"
                      id={this.state.emailId}
                      inputValue={email}
                      errorMsg={emailErrorMsg}
                      flag={emailFlag}
                      onChange={this.emailHandler}
                      refrence={this.setEmailFocus}
                      disabled={this.state.disableFields}
                    />
                  </div>
                </div>
                <ZipAddress
                   setCoutry={this.setCoutry}
                   setCityState={(city, state)=>{
                    this.setState({
                      city:city,
                      state:state,
                      cityFlag:false,
                      stateFlag:false
                    })
                   }}
                   handleCityChange={(city)=>{
                    this.setState({city:city, cityFlag:false})
                   }}
                   handleStateChange={(state=>{
                    this.setState({
                      state:state,
                      stateFlag:false
                    })
                   })}
                   Zipcode={this.state.zip5}
                   ZipcodeErrorMsg={this.state.zip5ErrorMsg}
                   ZipcodeFlag={this.state.zip5Flag}
                   zipCodeHandler={this.zip5Handler}
                  
                   city={this.state.city}
                   CitycodeErrorMsg={this.state.cityErrorMsg}
                   cityFlag={this.state.cityFlag}
                   StatecodeErrorMsg={this.state.stateErrorMsg}
                   state={this.state.state}
                   stateFlag={this.state.stateFlag}
                  />
       
              {/* <div
                id={"errorId"}
                aria-hidden={slectMethodflag ? "false" : "true"}
                role="alert"
                style={{ display: slectMethodflag ? "block" : "none" }}
              >
                <p
                  className="li_error_text"
                  style={{ display: slectMethodflag ? "block" : "none" }}
                >
                  Field Required
                </p>
              </div> */}
  

           
              {/* <div className="col-sm-6">
                <div className="input-b">

            <GetStatesContainer 
            inputValue={this.state.state}
            onChange={this.stateHandler}
            Flag={stateFlag}
            />
            </div>
            </div> */}
           
              {/* <div className="col-sm-6">
                <div className="input-b">
                  <LabelInput
                    idError="CityError"
                    type="city"
                    label="City"
                    star=""
                    name="city"
                    id={"CityId"}
                    inputValue={city}
                    errorMsg={cityErrorMsg}
                    flag={cityFlag}
                    onChange={this.cityHandler}
                  />
                </div>
              </div> */}
            </div>
            <div className="row">   
            <div className="col-sm-12">
              <h5 className="heading_Style">Schedule a demo with us!</h5>
            </div>
              <div className="col-sm-12">
              <div style={{ height: '880px', overflow: 'hidden' }}>
                  <InlineWidget url="https://calendly.com/rewards-cyzu/njt-rewards-onboarding" styles={{ height: '100%' }} />
              </div>
              </div>
            </div>
          <div className="row">
              <div className="col-sm-12">
                <p className="by_click">By clicking on the "Submit" button, you agree to our <a target="_blank" rel="noopener noreferrer" href="/termsofuse">Terms of Use</a> Policy. You may recieve email notifications
                from us and can opt out any time.
                 </p>
                </div>
         </div>
          <div className="row">
          <div className="col-sm-12">
            <div className="help-form-btn margin">
              <BUSINESSPROFILE_VIEW
                onClick={this.submitHandler}
                BusinessProfile_post_status={
                  this.props.BusinessProfile_post_status
                }
                BusinessProfile_Message={this.props.BusinessProfile_Message}
                initializeState={this.initializeState}
                clearPassword={this.clearPassword}
                Flags={this.props.Flags}
                setBusinessFlag={this.setBusinessFlag}
                setUserNameFlag={this.setUserNameFlag}
                setEmailFlag={this.setEmailFlag}
                showSuccess={this.showSuccess}
                disableFields={this.disableFields}
                business_name={this.state.businessName}
                user_name={this.state.userName}
                email_address={this.state.email.toLowerCase()}
              />
            </div>
          </div>
          </div>
          </div>

          {
            showThanksModal?
            <ThankYouModal
        showThanksModal={showThanksModal}
        handleShowThanksModal={this.handleShowThanksModal}  
      />:null
          }

        </div>
      </Fragment>
    );
  }
}

export default GetInTouch;
