import React, { Component } from "react";

import "../MainStyles.css";

import TransitPerxHeader from "../Header/TransitPerxHeader";
import FAQsNewDesign from "../FAQs/FAQsNewDesign";
import TransitPerxFooter from "../TransitPerxFooter/TransitPerxFooter";
import GetInTouch from "../GetInTouch/BusinessProfileContainer";
import preval from "preval.macro";
import ReactMarkdown from "react-markdown";
import ScheduleCall from "../ScheduleCall/ScheduleCall";
import TransitPerxSlider from "../TransitPerxSlider/TransitPerxSlider";
import TransitPerxWhy from "../TransitPerxWhy/TransitPerxWhy";
import ThankYouContact from "../ThankYouPage/ThankYouContact";
import Loading from "../../Utils/Loading";

export class TransitPerx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBlock: false,
      showThankYou: false,
      showZohoLoading: false,
    };
  }
  

  setShowThankYou =(showThankYou)=>{
    this.setState({
      showThankYou
    })
  }

  setBlock = () => {
    this.setState({
      showBlock: !this.state.showBlock,
    });
  };

  setZohoLoading =(showZohoLoading)=>{
    this.setState({
      showZohoLoading
    })
  }

  scrollToThankyou = () => {

    this.Thankyou.scrollIntoView({
      behavior: "smooth",
      // block: "end",
      inline: "nearest",
    });
  };

  render() {
    const { TransitPerx } = this.props;
    console.log("data", this.props.TransitPerx);
    return (
      <div className="home">
        <div className="site-wrap home">
          <div className="site-mobile-menu site-navbar-target">
            <div className="site-mobile-menu-header">
              <div className="site-mobile-menu-close mt-3">
                <span className="icofont-close js-menu-toggle"></span>
              </div>
            </div>
            <div className="site-mobile-menu-body">
              <ul className="site-nav-wrap">
                <li>
                  <a href={window.location.origin+"/#section1"} className="nav-link">
                    {TransitPerx.RetailerSec1.MenuItem1}
                  </a>
                </li>
                <li>
                  <a href={window.location.origin+"/#why"} className="nav-link">
                    {TransitPerx.RetailerSec1.MenuItem2}
                  </a>
                </li>
                <li>
                  <a href={window.location.origin+"/#contact"} className="nav-link">
                    {TransitPerx.RetailerSec1.MenuItem3}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="nav-link"
                    href={preval`module.exports = process.env.RETAILER_WEB_URL`}
                    rel="noopener noreferrer"
                  >
                    {TransitPerx.RetailerSec1.MenuItem4}
                  </a>
                </li>
                <li>
                  <a href={window.location.origin+"/#section2"} className="nav-link">
                    {TransitPerx.RetailerSec1.MenuItem5}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <TransitPerxHeader
            TransitPerx={this.props.TransitPerx}
            //  logo= {TransitPerx.RetailerSection1.Text1}
            //  menu1= {TransitPerx.RetailerSection1.MenuItem1}
            //  menu2= {TransitPerx.RetailerSection1.MenuItem2}
            //  menu3= {TransitPerx.RetailerSection1.MenuItem3}
          />
          <main id="main">
            <div className="hero-section transit-section-1">
              <div className="container">
                <div className="inner-container">
                  <div className="row align-items-center">
                    <div className="mobile-img">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="mob-res">
                            <img
                              src={TransitPerx.RetailerSec2.Image2.url}
                              className="image1"
                              alt="not found"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="njt-hadle-box">
                        <h2 className="transit-heading1">
                          {TransitPerx.RetailerSec2.Heeader}
                        </h2>
                        <div className="transit-text-app">
                          {TransitPerx.RetailerSec2.Description}
                        </div>
                        <div className="two-btn">
                          <div className="partner-button1">
                            <nav
                              className="site-navigation position-relative nav-menu"
                              role="navigation"
                              aria-label="Main"
                            >
                              <a href="#section2">
                                {TransitPerx.RetailerSec2.Button}
                              </a>
                            </nav>
                          </div>
                          {/* <div className="partner-button1 margin">
                            <a href="#">Call Support</a>
                        </div> */}
                        </div>
                        {/* <div className="transit-text-app margin-l">
                        Already a Partner? <a target="_blank" href={preval`module.exports = process.env.RETAILER_WEB_URL`}
                        >Login</a>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 resp-desk">
                      <div className="mob-res">
                        <img
                          src={TransitPerx.RetailerSec2.Image2.url}
                          className="image1"
                          alt="not found"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- .hero-section end --> */}
     
            <section id="section1">
              <TransitPerxSlider TransitPerx={this.props.TransitPerx} />
            </section>

            
            {/* <!-- .download-section end --> */}
            <div id="why" className="new-fourth-section">
              <TransitPerxWhy TransitPerx={this.props.TransitPerx} />
            </div>
            <div id="FAQs" className="fifth-section5">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="faq-t-box">
                      <h3 className="faq-heading">
                        {TransitPerx.RetailerSec5.Header}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="faq-b">
                  <FAQsNewDesign TransitPerx={this.props.TransitPerx} />
                </div>
              </div>
            </div>
            {/* <!-- .point-section end --> */}
            <div id="section2" className="partner-section">
              <div className="heading-box">
                <div className="container">
                  <div className="row align-items-center">
                    <div
                      className="col-sm-12"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="partner-head transit txt-cc">
                        <div className="trasit-partner">
                          <h2 className="partner-h-1">
                            <ReactMarkdown
                              source={TransitPerx.RetailerSec6.Header}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- .partner-section end --> */}
            <div className="get-transit-bo">
              <div
                style={{ width: "100%", float: "left" }}
                ref={(el) => {
                  this.Thankyou = el;
                }}
              >
                <GetInTouch scrollToThankyou={this.scrollToThankyou} />
              </div>
            </div>
            {/* <!-- get-in-touch end --> */}
            <div id="contact" className="partner-call-section">
              <div className="heading-box expert">
                <div className="container">
                  <div className="row align-items-center">
                    {
                      this.state.showThankYou ?
                      <div className="success-msge-box">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12">
                            <ThankYouContact/>
                          </div>
                        </div>
                      </div>
                      </div>
                      :
                      <div className="container">
                        {
                          this.state.showZohoLoading ?
                        
                        <div className="col-sm-12">
                          <Loading/>
                        </div>
                        :
                        <div>
                          <div className="col-sm-12">
                            <div className="partner-head transit">
                              <div className="trasit-partner">
                                <h2 className="partner-h-line">
                                  {TransitPerx.RetailerSec7.Header}
                                </h2>
                              </div>
                              <div className="shedule-call-btn">
                                <a className="link" href='!#' onClick={this.setBlock}>
                                  {TransitPerx.RetailerSec7.Button}{" "}
                                </a>
                              </div>
                            </div>
                          </div>

                          
                          <div
                            className="box-call-back"
                            style={{
                              display: this.state.showBlock ? "block" : "none",
                            }}
                          >
                            
                              <ScheduleCall setshowThankYou={this.setShowThankYou} setZohoLoading={this.setZohoLoading}/>
                          </div>
                        </div>
                        }
                      </div>
                 
                    }

                  </div>
                </div>
              </div>
            </div>
            {/* <!-- .partner-section end --> */}
          </main>
        </div>
        {/* <!-- .site-wrap --> */}

        <footer className="t-footer-wrap">
          <TransitPerxFooter CopyRights={TransitPerx.RetailerSec8.CopyRight} />
        </footer>
        {/* <!-- .njt-footer --> */}
        <a href="!#" className="back-to-top">
          <i className="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}

export default TransitPerx;
