import { CheckDuplicateBusinessStatus, CheckDuplicateBusinessActions } from './CheckDuplicateBusinessConstants';

const CheckDuplicateBusiness_initialState = {
    CheckDuplicateBusiness_Status: CheckDuplicateBusinessStatus.CheckDuplicateBusiness_Post.NEW,
    CheckDuplicateBusiness_Message: '',
    CheckDuplicateBusinessDetails: {},
    Flags:false
}
export default function (state = CheckDuplicateBusiness_initialState, action) {
    switch (action.type) {
        case CheckDuplicateBusinessActions.CheckDuplicateBusiness_Post.LOADING:
            return { ...state, 
            CheckDuplicateBusiness_Status: CheckDuplicateBusinessStatus.CheckDuplicateBusiness_Post.LOADING,CheckDuplicateBusiness_Message: '',
            CheckDuplicateBusinessDetails: {}
         }
        case CheckDuplicateBusinessActions.CheckDuplicateBusiness_Post.NEW:
            return { ...state, CheckDuplicateBusiness_Status: CheckDuplicateBusinessStatus.CheckDuplicateBusiness_Post.NEW}

        case CheckDuplicateBusinessActions.CheckDuplicateBusiness_Post.FAILED:
           
            return { ...state, CheckDuplicateBusiness_Status: CheckDuplicateBusinessStatus.CheckDuplicateBusiness_Post.FAILED, CheckDuplicateBusinessDetails: action.payload, CheckDuplicateBusiness_Message: action.error, Flags:action.Flags }
        case CheckDuplicateBusinessActions.CheckDuplicateBusiness_Post.SUCCESS:
            return { ...state, CheckDuplicateBusiness_Status: CheckDuplicateBusinessStatus.CheckDuplicateBusiness_Post.SUCCESS, CheckDuplicateBusinessDetails: action.payload, CheckDuplicateBusiness_Message: action.message }

        default:
            return { ...state }
    }
}
