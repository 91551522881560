import React, { Component } from "react";
import "../NjtHomeOne/NjtHomeOne.css";
import ScheduleCall from "../ScheduleCall/ScheduleCall";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import ThankYouContact from "../ThankYouPage/ThankYouContact";
import Loading from "../../Utils/Loading";

class NjtContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zohoLoading: false,
      showThankYou: false,
    };
  }

  setZohoLoading = (zohoLoading) => {
    this.setState({
      zohoLoading,
    });
  };

  setShowThankYou = (showThankYou) => {
    this.setState({
      showThankYou,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  // componentDidMount(){
  //     console.log("NJT Retailer Content", this.props.NJTLandingPage)
  //     //console.log("NJT Retailer Content", this.props.NJTLandingPage.Section2.Bullet_List)
  //   }

  render() {
    return (
      <main className="njt-contact-wrap">
        <div className="njt-conctact-body">
          {
              !(this.state.zohoLoading) ?

                this.state.showThankYou ?
                (<div className="container">
                    <div className="row">
                          <div className="col-sm-12">
                            <ThankYouContact/>
                          </div>
                        </div>
                </div>)
                :
              (<div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1 role="alert" className="njt-sign-heading">Contact Us</h1>
                </div>
              </div>

              <ScheduleCall
                setShowThankYou={this.setShowThankYou}
                setZohoLoading={this.setZohoLoading}
                //zohoLoading={this.state.zohoLoading}
              />
  
              <div className="njt-note-box">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="njt-not-txt">
                      <ReactMarkdown
                        plugins={[gfm]}
                        source={this.props.NJTLandingPage.ContactUsSection.Note}
                        allowDangerousHtml
                      ></ReactMarkdown>
                      {/* <b>Note:</b> You can also reach us at <a href="">(201) 298-8636</a>. */}
                    </div>
                  </div>
                </div>
              </div>
            </div>)

              :
              <div className="container">
              <div className="row">
                <div className="col-sm-12">
                    <div className="zoho-loader">
                        <Loading/>
                    </div>
                </div>
            </div>
            </div>
          } 
          

         
        </div>

      </main>
    );
  }
}

export default NjtContact;
