export const GetStatesActions= {
	
	GetStates_GET :{
		NEW:"GetStates_GET_NEW",
		SUCCESS:"GetStates_GET_SUCCESS",
		FAILED:"GetStates_GET_FALIURE",
		LOADING:"GetStates_GET_LOADING",
		UPDATE:"DisableGetStates_Update_SUCCESS",
	},
	
}


export const GetStatesStatus ={

	GetStates_GET :{
		NEW:"GetStates_GET_NEW",
		SUCCESS:"GetStates_GET_SUCCESS",
		FAILED:"GetStates_GET_FALIURE",
		LOADING:"GetStates_GET_LOADING",
	}

}
