import { TransitPerxStatus, TransitPerxActions } from './TransitPerxConstants';

const TransitPerx_initialState = {
    TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.NEW,
    TransitPerx_Message: '',
    TransitPerxDetails: {},
    Flags:false
}
export default function (state = TransitPerx_initialState, action) {
    switch (action.type) {
        case TransitPerxActions.TransitPerx_Post.LOADING:
            return { ...state, 
                TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.LOADING,TransitPerx_Message: '',
                TransitPerxDetails: {}
         }
        case TransitPerxActions.TransitPerx_Post.NEW:
            return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.NEW}

        case TransitPerxActions.TransitPerx_Post.FAILED:
           
            return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.FAILED, TransitPerxDetails: action.payload, TransitPerx_Message: action.error, Flags:action.Flags }
        case TransitPerxActions.TransitPerx_Post.SUCCESS:
            return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.SUCCESS, TransitPerxDetails: action.payload, TransitPerx_Message: action.message }

        default:
            return { ...state }
    }
}
