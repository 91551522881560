import React, { Component } from 'react'
import image from "../../Assets/Images/rider1.png";

class RiderSectionOne extends Component {
    render() {
        return (
            <div className="rider-sec-warp-2">
                <div className="hero-section transit-section-1">
              <div className="container">
                <div className="inner-container">
                  <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                      <div className="mob-res">
                        <img
                          src={image}
                          className="image1"
                          alt="not found"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="njt-hadle-box">
                        <h2 className="transit-heading1">
                        Increase your <br /> customer outreach
                        </h2>
                        <div className="transit-text-app">
                        Enroll your business in TransitPerx <br />
                        and reach a greater audience
                        </div>
                        <div className="two-btn">
                        <div className="partner-button1">
                            <a href="#section2">Become a Partner</a>
                        </div>
                        {/* <div className="partner-button1 margin">
                            <a href="#">Call Support</a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        )
    }
}

export default RiderSectionOne
