import React, {  Fragment, PureComponent } from 'react'

import { TermAndConditionsStatus } from './TermAndConditionsConstants';

import TermAndCondition from "./TermAndCondition"
import Loading from '../../Utils/Loading';
import { withRouter } from "react-router-dom";


import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class TermAndConditionsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading:false,
      showApp:false,
      success: false
    }
    props.resetReducerState()


    this.props.getTermAndConditions();

    console.log(this.props.cookies.get("retailer_details"))

  }

 
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  getScreen(status) {
   
    switch (status) {
      case TermAndConditionsStatus.TermAndConditions_GET.NEW:
        console.log("In New of Term and Condition")
        
        console.log("After getTransit Perx in TOC New", this.props.TransitPerx)
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%",height: "100vh" }}>
            <Loading/>
          </div>
        );

      case TermAndConditionsStatus.TermAndConditions_GET.SUCCESS:
        // alert("test")
        if(!this.state.success){
         


          console.log("Terms and Conditions version", this.props.TermAndConditions)

          
        }
        

        this.state.success = true
        
          return (
            <Fragment >
              <TermAndCondition
              cookies = {this.props.cookies}
              TermAndConditions = {this.props.TermAndConditions}
              TransitPerx={this.props.TransitPerx}
      
              />
            </Fragment>
          );
        

      
      case TermAndConditionsStatus.TermAndConditions_GET.FAILED:

      console.log("In GET FAILED of Term and Condition")
        return (
          <Fragment >
            {this.props.TermAndConditions_Msg !=="" && this.props.TermAndConditions_Msg !== "Error: Provide Email to Encrypt."?
                <Snackbar
                open={this.state.open}
                autoHideDuration={2000}
                onClose={this.closeSnackBar}
              >
                <Alert onClose={this.closeSnackBar} severity="error">
                  {this.props.TermAndConditions_Msg}
                </Alert>
              </Snackbar>:
              null
            }
            <h1 style={{textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%",height: "100vh",color: '#730069'}}>The server could not be contacted. This may be a temporary glitch or the server may be down.Please try again in a while</h1>
          </Fragment>
        );
        case TermAndConditionsStatus.TermAndConditions_GET.NOTACTIVATED:    
      if(this.state.loading)
     { 
          
      this.props.cookies.set(
        "notActivated",
        true,
        { path: "/" }
      );
      //  alert(this.state.loading)
       this.state.loading = false
       if(!this.state.showApp){
        this.setState({
          showApp:true
        },()=>{
          this.props.history.replace("/Dashboard")
        })
      }else{
        this.props.history.replace("/Dashboard")
      }
            
      }
          return (  
            <Fragment >
               <Snackbar
                open={this.state.open}
                autoHideDuration={2000}
                onClose={this.closeSnackBar}
              >
                <Alert onClose={this.closeSnackBar} severity="error">
                  {this.props.TermAndConditions_Msg}
                </Alert>
              </Snackbar>
              {/* <RiderHome cookies = {this.props.cookies} /> */}
            </Fragment>
          );
      case TermAndConditionsStatus.TermAndConditions_GET.LOADING:
            this.state.loading=true
            console.log("In GET LOADING of Term and Condition")
            console.log("After getTransit Perx in TOC Loading", this.props.TransitPerx)
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%",height: "100vh" }}>
            <Loading/>
          </div>
        );
      default:
        return <div />
    }

  }
  render() {

    return (
      <Fragment>
       {this.getScreen(this.props.TermAndConditions_GET_status)}
       </Fragment>
    )
  }
}

export default withRouter(TermAndConditionsView)
