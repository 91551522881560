import React,  { 
   Component }  from 'react';
import './App.css';
import {Route, withRouter, Redirect, Switch} from 'react-router-dom'
import { withCookies } from 'react-cookie';
import AccountPayment from './Components/AccountPayment/AccountPaymentContainer';
import TransitPerx from './Components/TransitPerx/TransitPerxContainer';
import RiderHome from './Components/RiderHome/RiderHome';
import TermAndConditionsContainer from './Components/TermAndCondition/TermAndConditionsContainer';
import {CMS_URL, RETAILER_FAVICON_ID, RETAILER_LANDINGPAGE_GA_CODE, BRANDING
} from './Config/Config'
import Axios from 'axios'
import Analytics from 'react-router-ga'
import NjtHomeOne from './Components/NjtHomeOne/NjtHomeOne'
import NjtContact from './Components/NjtContact/NjtContact';
import NjtFaqPage from './Components/NjtFaqPage/NjtFaqPage';

import NjtHeader from "./Components/Header/NjtHeader";
import NjtFooter from "./Components/NjtFooter/NjtFooter";
import RiderTeaserPage from './Components/RiderTeaserPage/RiderTeaserPage';
import ErrorPage from './Components/404/404';
import NjtSignUpFormContainer from './Components/NjtSignUpForm/NjtSignUpFormContainer';
import NjtSignpUpPremium from './Components/NjtSignpUpPremium/NjtSignpUpPremium'
import TransitPerxPage from './Components/TransitPerxLandingPage/TransitPerxPage/TransitPerxPage'

import {branding} from 'RefRecordsConstants'

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      NJTPartnerLandingPage:'',
      is_rapid_registration_on: 'true'
    }

    // alert(JSON.stringify(branding.TransitPerxBranding))
    
  }


  componentDidMount(){
    this.fetchRetailerFavicon()
  }

  setPartnerRapidRegistration = (is_rapid_registration_on)=>{
    this.setState({
      is_rapid_registration_on
    })
  }
  

  fetchRetailerFavicon=async()=>{
    const apiID = 'retailer-favicon-icons'
    const inID = RETAILER_FAVICON_ID

    const url = CMS_URL+`/${apiID}/${inID}`;

    // console.log(url)
    try {
      const fetchFavData = await Axios.get(url)
      console.log("fetchFavData",fetchFavData)
      //Change the Title of Page i-e make it dynamic
      const newTitle = fetchFavData.data.Title;
  
      document.title = newTitle;

      //Change the Thumbnail of the page
      const favURL = fetchFavData.data.FaviconImage[0].url;
    
      document.getElementById("retailer_fav_icon").href = favURL;
      document.getElementById("retailer_fav_icon_apple").href = favURL;
      console.log('fav url', favURL);

    } catch (error) {
      console.log(error)
    }
    
  }

  render() {
    // alert(this.props.history.location.pathname)
  return (
    <div className="App">
      {/* <ColorTheme/> */}

    
        {((this.props.history.location.pathname==="/teasertageold" || this.props.history.location.pathname==="/NJT_Partner_Registration_Application_Submission")) ? "": ( BRANDING === branding.TransitPerxBranding ? "":<NjtHeader  cookies={this.props.cookies}/>)}

        <Analytics id={RETAILER_LANDINGPAGE_GA_CODE}>
        <Switch>
        <Route
            exact
            path="/NJT_Partner_Registration_Application_Submission"
            render={() => <NjtSignpUpPremium NJTLandingPage={this.props.App} location ={this.props.location} />}
          />
        <Route
            exact
            path="/"
            render={() => BRANDING === branding.TransitPerxBranding ? <TransitPerxPage/> : <NjtHomeOne NJTLandingPage={this.props.App} location ={this.props.location} />}
          />
            <Route
            exact
            path="/teasertageold"
            render={() => <RiderTeaserPage NJTLandingPage={this.props.App} location ={this.props.location} />}
          />
         {/* <Route
            exact
            path="/RETAILER_LANDING_PAGE_WEB_URL/PLPforPostLaunchHidehere"
            render={() => <NjtHomeOne NJTLandingPage={this.props.App} location ={this.props.location} />}
          /> */}
          <Route
            exact
            path="/SignUp"
            render={() =>
              <NjtSignUpFormContainer
              NJTLandingPage={this.props.App}
              location ={this.props.location}
              setPartnerRapidRegistration={this.setPartnerRapidRegistration}
              is_rapid_registration_on={this.state.is_rapid_registration_on}
              />}
          />
          {/* <Route
            exact
            path="/Contact"
            render={() => <NjtContact NJTLandingPage={this.props.App} location ={this.props.location} />}
          /> */}
          <Route
            exact
            path="/FAQs"
            render={() => <NjtFaqPage NJTLandingPage={this.props.App} location ={this.props.location} />}
          />
           <Route
            exact
            path="/PastPage"
            render={() => <TransitPerx location ={this.props.location} />}
          />
        {/* <Route
            exact
            path="/Partner"
            render={() => <Partner />}
        /> */}
        <Route
            exact
            path="/termsofuse"
            render={() => <TermAndConditionsContainer />}
        />
    
        {/* <Route
            exact
            path="/TermsOfUse"
            render={() => <TermsOfUse />}
        /> */}
         <Route
            exact
            path="/Rider"
            render={() => <RiderHome />}
        />
        <Route
            exact
            path="/PaymentAccount"
            render={() => <AccountPayment 
              BussinessId={12}
              BussinessName={'AlfAin Technologies Private Limited'}
              email={'alfain@gmail.com'}
              ProgramFee={100}
            />}
        />

        {/* <Route exact path="*">
            <Redirect to="/" />
        </Route> */}

        <Route exact path="/partner">
            <Redirect to="/" />
        </Route>
        {/* <Route  path="/*">
            <Redirect to="/" 
            
            />
        </Route> */}
        <Route
            exact
            path="*"
            render={() => <ErrorPage  NJTLandingPage={this.props.App} location ={this.props.location} />}
          />
        </Switch>
        </Analytics>
       {((this.props.history.location.pathname==="/teasertageold" || this.props.history.location.pathname==="/NJT_Partner_Registration_Application_Submission")) ?"" : ( BRANDING === branding.TransitPerxBranding ? "": <NjtFooter NJTLandingPage={this.props.App}/>)}
    </div>
  );
}
}

export default withRouter(withCookies(App));
