
//******************Exchange Rate Server Calls******************;

import {TermAndConditionsActions} from './TermAndConditionsConstants';
import { TransitPerxActions } from '../TransitPerx/TransitPerxConstants';
import store from '../../Store/store'
import {ROOT_URL, CMS_URL, RETAILER_LANDING_PAGE_ID} from '../../Config/Config';
import {failure_messages} from "Messages"


export const TermAndConditionsServer = {
  //  getTermAndConditions:getTermAndConditions,
  getTermAndConditions:getTermAndConditions,
  updateRetailerVersionTerms:updateRetailerVersionTerms,
  getTransitPerx:getTransitPerx,
};

//******************Insert TermAndConditionss******************;

function getTermAndConditions(data){

      const id = 1; 
      fetch(CMS_URL + `/retailer-term-and-conditions/${id}`, {
          method: 'GET',
          mode: 'cors',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             console.log("hamza bhai",response)

             if(response.id && response.TermsAndConditions){        
           {
             let version = 0
             let terms = false
             response.TermsAndConditions.forEach(element => {
              if(version < element.Version)
              {
                terms = element
                version= element.Version
              }
            });
            if(terms)
             {
              
               store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.SUCCESS,payload:{...terms, version:version}});
             }
             else
             store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:response.error});
             }
            return ;
            }else
            {
              store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:response.error});
              return ;
              }    
           }).catch((error) => {
            //  alert(error)
             store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:failure_messages.unExpectedError});
              })
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:TermAndConditionsActions.TermAndConditions_GET.LOADING}

};


function getTransitPerx(){
  // alert(CMS_URL)
  fetch(CMS_URL+`/new-retailer-pages/${RETAILER_LANDING_PAGE_ID}`, {
  method: 'GET',
  mode: 'cors',
  headers: {'Content-Type':'application/json'},
  // body: JSON.stringify(data)
  }).then((response)=>{
  // console.log("responce ",response)
  response.json().then(response=>{
  
  //console.log(response)
  if(response.id)
  {
    store.dispatch({type:TransitPerxActions.TransitPerx_Post.SUCCESS, payload:response});
  }
  else
  {
    store.dispatch({type:TransitPerxActions.TransitPerx_Post.FAILED});
  }
  return ;
  }).catch((error) => {
  store.dispatch({type:TransitPerxActions.TransitPerx_Post.FAILED});
  })
  
  })
  return {type:TransitPerxActions.TransitPerx_Post.LOADING}
  
  }




//******************Get TermAndConditionss******************;


//******************Update TermAndConditions******************;

function updateRetailerVersionTerms(data){
  fetch(ROOT_URL+'/api/terms/updateRetailerVersionTerms', {
      method: 'Post',
      mode: 'cors',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(data)
      }).then((response)=>{
       response.json().then(response=>{

         if(response.status == "200"){
         // alert(JSON.stringify(response))
        store.dispatch({type:TermAndConditionsActions.TermAndConditions_POST.SUCCESS,payload:response.data});
        return ;
        }else
        {
          store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:response.error});
          return ;
          }    
       });
      }).catch((error) => {
      //  alert(error)
       store.dispatch({type:TermAndConditionsActions.TermAndConditions_GET.FAILED, error:failure_messages.unExpectedError});
        })
  return {type:TermAndConditionsActions.TermAndConditions_GET.LOADING}

};







