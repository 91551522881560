import React, { Component, Fragment } from "react";

class NjtFAQs extends Component {
  componentDidMount(){
    console.log("NJT Retailer Content", this.props.FAQ.FAQHeading)
    console.log("NJT Retailer Content", this.props.FAQ.FAQPart)
  }

  render() {
    return (
        <Fragment>
              <div className="container">
          <div className="row">
                            <div className="col-sm-12">
                                <div className="faq-heading-box">
                                <h1 role="alert" className="njt-sign-heading">
                                {/* Frequently Asked Questions */}
                                {this.props.FAQ.FAQHeading}
                                </h1>
                                </div>
                            </div>
                        </div>
          <div className="row">
           <div className="col-sm-12">
          <div class="accordion accordion-flush custome_accor" id="accordionFlushExample">
          {this.props.FAQ.FAQPart.map((item, index) => {
              return (
                <div class="accordion-item"  key={index}>
                <h2 class="accordion-header" id={"flush-heading"+item.id}>
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-"+item.id} aria-expanded="false" aria-controls="flush-collapseOne">
                  {item.Question}
                  </button>
                </h2>
                <div id={"flush-"+item.id} class="accordion-collapse collapse" aria-labelledby={"flush-heading"+item.id} data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  {item.Answer}
                  </div>
                </div>
              </div>
                );
              })}
</div>
</div>
</div>
</div>

      </Fragment>
    );
  }
}

export default NjtFAQs;
