export const NjtSignUpFormActions= {
	
	NjtSignUpForm_Post :{
		NEW:"NjtSignUpForm_Post_NEW",
		SUCCESS:"NjtSignUpForm_Post_SUCCESS",
		FAILED:"NjtSignUpForm_Post_FALIURE",
		LOADING:"NjtSignUpForm_Post_LOADING",
	}
}


export const NjtSignUpFormStatus ={

	NjtSignUpForm_Post :{
		NEW:"NjtSignUpForm_Post_NEW",
		SUCCESS:"NjtSignUpForm_Post_SUCCESS",
		FAILED:"NjtSignUpForm_Post_FALIURE",
		LOADING:"NjtSignUpForm_Post_LOADING",
		OVERIDE:"NjtSignUpForm_Post_OVERIDE"
	}

}
