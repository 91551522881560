
//******************Exchange Rate Server Calls******************;

import { TransitPerxActions } from './TransitPerxConstants';
import store from '../../Store/store'
import {
   CMS_URL, RETAILER_LANDING_PAGE_ID } from '../../Config/Config';

export const TransitPerxServer = {
getTransitPerx:getTransitPerx,

};



//******************Get TransitPerxs******************;


//******************Update TransitPerx******************;


function getTransitPerx(){
// alert(CMS_URL)
fetch(CMS_URL+`/new-retailer-pages/${RETAILER_LANDING_PAGE_ID}`, {
method: 'GET',
mode: 'cors',
headers: {'Content-Type':'application/json'},
// body: JSON.stringify(data)
}).then((response)=>{
// console.log("responce ",response)
response.json().then(response=>{

console.log(response)
if(response.id)
{
  store.dispatch({type:TransitPerxActions.TransitPerx_Post.SUCCESS, payload:response});
}
else
{
  store.dispatch({type:TransitPerxActions.TransitPerx_Post.FAILED});
}
return ;
}).catch((error) => {
store.dispatch({type:TransitPerxActions.TransitPerx_Post.FAILED});
})

})
return {type:TransitPerxActions.TransitPerx_Post.LOADING}

}