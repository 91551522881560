import React, { Component } from 'react'
import "./TransitPerxHeader.css"
import {Helmet} from "react-helmet";

class TransitPerxHeader extends Component {
    render() {
        return (
            <>
    <Helmet>
      <meta name="keywords" content="TransitPerx" />
    <meta property="og:title" content="TransitPerx" />
    <meta property="og:image:secure_url" content="https://transitperxbranding.s3.amazonaws.com/transitPerx-logo.png" />
    <meta property="og:image" content="%PUBLIC_URL%/favicon-TrasitPerx.png" />
    <meta
      name="description"
      content="TransitPerx Reward Program. Travel, Redeem, Repeat."
    />
    </Helmet>
            <div className="perx-header-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="perx-logo">
                                {/* <a href="/"> */}
                                <img src="https://transitperxbranding.s3.amazonaws.com/perx-new-logo.svg" alt="not found" />
                                <span className="perx-logo-txt">TransitPerx</span>
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="perx-link-schdule">
                                <a className="perx-link-nav" href="#schedule">Schedule a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            </>
        )
    }
}

export default TransitPerxHeader
