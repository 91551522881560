import React, { Component } from "react";
import { Snackbar as MUISnackbar} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { failure_messages } from "Messages";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
class Snackbar extends Component {
    
    render() {
        const {open, onClose, severity, message, Logout_Message} = this.props
        // alert(message)
        if(message ===  failure_messages.SessionExpired && Logout_Message !== message)
        {
            return null
        }
        // alert(this.props.Logo    ut_Message)
        return (

            <MUISnackbar
            open={open}
            autoHideDuration={2000}
            onClose={onClose}
          >
            <Alert onClose={onClose} severity={severity}>
              {message}
            </Alert>
          </MUISnackbar>

        )
    }
}


export default Snackbar
