import { connect } from "react-redux";
import TimeToCallView from "./TimeToCall_View";
import {TimeToCallServer} from "./server"
const mapStateToProps = state => {
    console.log(state)
    return {
        TimeToCall_GET_status:state.TimeToCallReducer.TimeToCall_Status,
        TimeToCall:state.TimeToCallReducer.TimeToCall,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getTimeToCall: (data, token) => {

           dispatch( TimeToCallServer.getTimeToCall(data, token) )
        },
        
    }
};
const TimeToCallContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeToCallView)

export default TimeToCallContainer;