import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { RETAILER_WEB_URL, RIDER_WEB_URL,LANDING_PAGE_END_BRANDING_IMAGE_URL } from "../../Config/Config"

class HeaderPremiumForm extends Component {

  render() {

    return (
      <header className="njt-landing-header">
      <div className="row">
        <div className="col-sm-12">
          <nav className="navbar navbar-expand-lg fixed-top navbar-light">
            <div className="container">
              <div className="njt-logo-header">
                <Link className="navbar-brand" to="/">
                  <img src={LANDING_PAGE_END_BRANDING_IMAGE_URL+"Njt-Logo-All.svg"}
                    alt="NJT Rewards® Logo" />
                </Link>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <nav
                  className="site-navigation position-relative nav-menu"
                  role="navigation"
                    aria-label="Main"
                  >
                  <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                      <a
                        className="nav-link border"
                        target={"_blank"}
                        href={RIDER_WEB_URL}
                        rel="noopener noreferrer"
                      >
                        Customers
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className="nav-link border"
                        href="/SignUp"
                      >
                        Business Sign Up
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a
                        href={RETAILER_WEB_URL}
                        target={"_blank"}
                        className="nav-link bg-color"
                        rel="noopener noreferrer"
                      >
                        Log In
                        </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </nav>
        </div>
      </div>
      </header>
    )
  }
}

export default HeaderPremiumForm
