import { connect } from "react-redux";
import TermAndConditionsView from "./TermAndConditions_View";
import {TermAndConditionsServer} from "./server"
import {TermAndConditionsActions} from './TermAndConditionsConstants'
import {withCookies} from "react-cookie"
const mapStateToProps = state => {
    console.log(state)
    return {
        TermAndConditions_GET_status:state.TermAndConditionsReducer.TermAndConditions_Status,
        TermAndConditions:state.TermAndConditionsReducer.TermAndConditionsDetails,
        TermAndConditions_Msg:state.TermAndConditionsReducer.TermAndConditions_Msg,
        RiderUser:state.TermAndConditionsReducer.RiderUser,
        RiderPoints:state.TermAndConditionsReducer.RiderPoints,
        TransitPerx_post_status:state.TransitPerxReducer.TransitPerx_Status,
        TransitPerx_Message:state.TransitPerxReducer.TransitPerx_Message,
        TransitPerx:state.TransitPerxReducer.TransitPerxDetails,
        Flags:state.TransitPerxReducer.Flags,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        // API call for getting details from Strapi Again
        getTransitPerx: () => {
            dispatch( TermAndConditionsServer.getTransitPerx() )
        },

        getTermAndConditions: (data) => {

           dispatch( TermAndConditionsServer.getTermAndConditions(data) )
        },
        resetReducerState:()=>{
            dispatch({type:TermAndConditionsActions.TermAndConditions_GET.NEW})
        },
        updateRetailerVersionTerms:(data)=>{
            dispatch( TermAndConditionsServer.updateRetailerVersionTerms(data) )
        }
    }
};
const TermAndConditionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermAndConditionsView)

export default withCookies(TermAndConditionsContainer);