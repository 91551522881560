import React, { Component } from "react";

class FAQsNewDesign extends Component {
  render() {
    const {
      // Question =" How long does it take to become a TransitPerx partner?",
      // Answer = "NJT Rewards is a new program to thank you, our loyal NJ TRANSIT customer, by awarding you points you can redeem for Rewards based on travel. The more you travel, the more Rewards you earn! Points are   redeemable to save money on select tickets or offers  at a nearby retailer. Through NJT Rewards, turn your  NJ TRANSIT ticket purchases into Rewards, including",
    
      // data=data1,
      TransitPerx
    } = this.props;
    return (
      <div className="faq-new-design">
        <div className="tab-content">
          <div id="menu1" className="faqs-accord tab-pane fade in active show">
            <div className="container">
              <div className="inner-container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="accordion" id="accordionExample">
                    {TransitPerx.RetailerSec5.RetailerSubsection55.map((item, index) => {
              return (
               <div className="card">
               <div className="card-header" id="headingOne">
                 <h2 className="mb-0 border-top">
                   <button
                     className="btn btn-link collapsed"
                     type="button"
                     data-toggle="collapse"
                     data-target={"#collapse"+item.id}
                     aria-expanded="false"
                     aria-controls="collapseOne"
                     aria-label="collapse"
                   >
                     {item.Question}
                     <span className="material-icons plus">add</span>
                     <span class="material-icons remove">remove</span>
                   </button>
           
                 </h2>
               </div>

               <div
                 id={"collapse"+item.id}
                 className="collapse"
                 aria-labelledby="headingOne"
                 data-parent="#accordionExample"
               >
                 <div className="card-body">
                   {item.Answer}
                 </div>
               </div>
             </div>
                        );
                      })}
                      
                      {/* <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              How does pricing work?
                              <span className="material-icons plus">add</span>
                              <span class="material-icons remove">remove</span>
                            </button>
                            
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            When logging into the NJ TRANSIT Mobile App®, tap
                            NJT Rewards and you will be directed to the Rewards
                            section to sign-up and become a member. From here,
                            you will see all your NJT Rewards detailed
                            accordingly. Then, once your NJ TRANSIT ticket is
                            purchased and activated, points automatically
                            accumulate to your account. Points can then be
                            redeemed for Rewards. The program rollout will be
                            route specific. As the program builds and adds more
                            routes, it will eventually incorporate the entire NJ
                            TRANSIT system. NJT Rewards works in the background
                            of your commute and earns points for you every time
                            you activate your ticket or pass.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                             Who is using TransitPerx?
                             <span className="material-icons plus">add</span>
                              <span class="material-icons remove">remove</span>
                            </button>
                            
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Information required includes your name, email
                            address and zip code. As a user of the NJ TRANSIT
                            Mobile App, simply tap Rewards and earn points in no
                            time!
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              When can I start using TransitPerx?
                              <span className="material-icons plus">add</span>
                              <span class="material-icons remove">remove</span>
                            </button>
                           
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            NJ TRANSIT will never sell or misuse your
                            information. Your personal information will be used
                            in compliance with our Terms of Use as set forth in
                            our privacy policy (link here for Terms of
                            Use/Privacy Policy).
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              How will my business benefit from TransitPerx?
                              <span className="material-icons plus">add</span>
                              <span class="material-icons remove">remove</span>
                            </button>
                          
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            No, NJT Rewards is FREE, but in order to earn points
                            to redeem Rewards, you must purchase and activate
                            your travel tickets through the NJ TRANSIT Mobile
                            App. Each NJ TRANSIT ticket purchase provides your
                            account with instant points. The more points you
                            accumulate, the more Rewards you can redeem,
                            including discounts on your NJ TRANSIT travel
                            tickets, and more!
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQsNewDesign;
