export const AppActions= {
	
	App_GET :{
		NEW:"App_GET_NEW",
		SUCCESS:"App_GET_SUCCESS",
		FAILED:"App_GET_FALIURE",
		LOADING:"App_GET_LOADING",
		NOTACTIVATED:"RIDER_ACCOUNT_NOT_ACTIVATED"
	},
	App_POST :{
		NEW:"App_POST_NEW",
		SUCCESS:"App_POST_SUCCESS",
		FAILED:"App_POST_FALIURE",
		LOADING:"App_POST_LOADING",

	},
}


export const AppStatus ={

	App_GET :{
		NEW:"App_GET_NEW",
		SUCCESS:"App_GET_SUCCESS",
		FAILED:"App_GET_FALIURE",
		LOADING:"App_GET_LOADING",
		NOTACTIVATED:"RIDER_ACCOUNT_ACTIVATED"
	},
	App_POST :{
		NEW:"App_POST_NEW",
		SUCCESS:"App_POST_SUCCESS",
		FAILED:"App_POST_FALIURE",
		LOADING:"App_POST_LOADING",

	},

}
