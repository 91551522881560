import { AppStatus, AppActions } from './AppConstants';

const App_initialState = {
    App_Status: AppStatus.App_GET.NEW,
    App_Msg: '',
    AppDetails: {},
    RiderPoints:0,
    encrypted_key:false,
    RiderUser:false,
}
export default  function  (state = App_initialState, action) {
    switch (action.type) {
        case AppActions.App_GET.LOADING:
            return { ...state, App_Status: AppStatus.App_GET.LOADING }
            case AppActions.App_GET.NEW:
                return { ...state, App_Status: AppStatus.App_GET.NEW, AppDetails: [] }
    
        case AppActions.App_GET.FAILED:
            
            return { ...state,  App_Status: AppStatus.App_GET.FAILED, App_Msg:action.error}
        case AppActions.App_GET.SUCCESS:
          //console.log(action.payload)
          return { ...state,  App_Status: AppStatus.App_GET.SUCCESS, AppDetails:action.payload}
          
          case AppActions.App_GET.NOTACTIVATED:
    
          return { ...state,  App_Status: AppStatus.App_GET.NOTACTIVATED, App_Msg:action.message}
          
          case AppActions.App_POST.SUCCESS:
          
            return { ...state,  App_Status: AppStatus.App_POST.SUCCESS}
          
        default:
            return { ...state
            }
    }
}
