import React, { Component, Fragment } from "react";
import { NjtSignUpFormStatus } from "./NjtSignUpFormConstants";
import NjtSignUpForm from "./NjtSignUpForm";
import Loading from "../../Utils/Loading";
import Snackbar from "../../Utils/Snackbar";

import { withCookies } from "react-cookie";

export class NjtSignUpFormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false,
     
    };

    console.log("childPage",props)
    
  }

  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  componentWillUnmount() {
    this.props.resetReducerState();
  }

  getScreen(status) {
    switch (status) {
      case NjtSignUpFormStatus.NjtSignUpForm_Post.NEW:
        return (
          <Fragment>
            <NjtSignUpForm 
            NJTLandingPage={this.props.NJTLandingPage}
              // is_rapid_registration_on={this.props.is_rapid_registration_on}
            />
          </Fragment>
        );

      case NjtSignUpFormStatus.NjtSignUpForm_Post.SUCCESS:
        
        
        console.log('partner reg', this.props.NjtSignUpForm)

        if (!this.state.success){
          this.state.success = true
        }

        return (
          <Fragment>
            <NjtSignUpForm
             NJTLandingPage={this.props.NJTLandingPage}
              // is_rapid_registration_on={this.props.NjtSignUpForm.PartnerRapidRegistrationOn}
            />
          </Fragment>
        );
        
      case NjtSignUpFormStatus.NjtSignUpForm_Post.FAILED:
        
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="error"
              message={this.props.NjtSignUpForm_Message}
            />
            <NjtSignUpForm
             NJTLandingPage={this.props.NJTLandingPage}
              // is_rapid_registration_on={this.props.is_rapid_registration_on}
            />
          </Fragment>
        );
        
      case NjtSignUpFormStatus.NjtSignUpForm_Post.LOADING:
        return <div style={{display:"flex", flexDirection:'column', justifyContent:"center", alignItems:"center", height:"100vh" }}><Loading/></div>
      
      default:
        break;
    }
  }
  render() {
    return this.getScreen(this.props.NjtSignUpForm_post_status);
  }
}

export default withCookies(NjtSignUpFormView);
