import React, { Component } from 'react'
import HeaderPremiumForm from '../Header/HeaderPremiumForm'
import FooterPremiumForm from '../NjtFooter/FooterPremiumForm'
import NjtSignUpForm from "../NjtSignUpForm/NjtSignUpForm";

class NjtSignpUpPremium extends Component {
    state = {
        is_rapid_registration_on: 'false'
    }

    render() {
        const {is_rapid_registration_on} = this.state

        return (
            <div className="premium-box">
               <HeaderPremiumForm/>
               {/* <div className="premium-inner-wrap">
                   <div className="container">
                   <div className="row">
                            <div className="col-sm-12">
                                <h2 className="njt-sign-heading">Become a partner</h2>
                            </div>
                        </div>
                   </div>
                   please put form there
               </div> */}
                <NjtSignUpForm is_rapid_registration_on={is_rapid_registration_on}/>
               <FooterPremiumForm/>
            </div>
        )
    }
}

export default NjtSignpUpPremium
