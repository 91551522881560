import React, { Component, Fragment } from "react";
import { BusinessProfileStatus } from "./BusinessProfileConstants";
import Loading from "../../Utils/Loading";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class BusinessProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success:false,
      loading:false
    };
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  static getDerivedStateFromProps(props, state) {
    
    switch (props.BusinessProfile_post_status) {
      case BusinessProfileStatus.BusinessProfile_Post.SUCCESS:
        if(state.loading)
        {
          state.loading = false
         props.initializeState()
         props.showSuccess()
         props.disableFields(false)
        }
        break;

      case BusinessProfileStatus.BusinessProfile_Post.FAILED:
        if(props.Flags)
        
        if(state.loading)
        {
          state.loading = false
          let Flags = props.Flags
          if(Flags){
            if(Flags.emailExistsFlag)
            props.setEmailFlag()
            if(Flags.usernameExistsFlag)
            props.setUserNameFlag()
            if(Flags.business_exists)
            props.setBusinessFlag()
          }
          // props.clearPassword()

          props.disableFields(false)
         }
        break;

      case BusinessProfileStatus.BusinessProfile_Post.LOADING:
        state.open = true;
        
        if(!state.loading){

           state.loading = true
          props.disableFields(true)
        }
        break;

      default:
        break;
    }

     return null;
  }

  getScreen(status) {
    switch (status) {
      case BusinessProfileStatus.BusinessProfile_Post.NEW:
        return (
          <Fragment>
            <button
                  className={"help-submit-btn"}
                  onClick={this.props.onClick}
                  disabled={this.props.acceptButtonDisabled}
                >
                  Submit
                </button>
          </Fragment>
        );

      case BusinessProfileStatus.BusinessProfile_Post.SUCCESS:
        return (
          <Fragment>
                        <button
                  className="help-submit-btn"
                  onClick={this.props.onClick}
                  disabled={this.props.acceptButtonDisabled}
                >
                  Submit
                </button>
         
          </Fragment>
        );
    
      case BusinessProfileStatus.BusinessProfile_Post.FAILED:
        
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="error">
                {this.props.BusinessProfile_Message}
              </Alert>
            </Snackbar>
            <button
                  className="help-submit-btn"
                  onClick={this.props.onClick}
                  disabled={this.props.acceptButtonDisabled}
                >
                  Submit
                </button>
          </Fragment>
        );
      case BusinessProfileStatus.BusinessProfile_Post.LOADING:
         
        // }
        return <div style={{display:"flex", alignItems:"flex-end",justifyContent: "flex-end", paddingRight:35}}>
        <Loading />
        </div>
      default:
        break;
    }
  }
  render() {
    return this.getScreen(this.props.BusinessProfile_post_status);
  }
}

export default BusinessProfileView;
