import React, { Component } from 'react'
import "./TransitPerxSchedule.css"
import LabelInput from '../../Inputs/LabelInput'
import InputMask from '../../Inputs/MaskInput/MaskInput'
import {ROOT_URL} from '../../../Config/Config'
import Axios from 'axios'
import TimeToCallContainer from '../../GetInTouch/TimeToCall/TimeToCallContainer'
import LabelTextAreaInput from '../../Inputs/LabelTextAreaInput'
import moment from 'moment/moment'

class TransitPerxSchedule extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            email: "",
            // emailId: "",
            emailErrorMsg: "Field Required",
            emailFlag: "",
            userName: "",
            // userId: "",
            userNameErrorMsg: "Field Required",
            userNameFlag: "",
            bestTime: "",
            bestTimeErrorMsg: "Field Required",
            bestTimeFlag: "",
            phone: "",
            phoneErrorMsg: "Field Required",
            phoneFlag: "",
            open: false,
            request: "",
            requestErrorMsg: "Field Required",
            requestFlag: ""
        }
    }



// userNameHandler Function ...................................
  userNameHandler = (e) => {
    const reg = /^[a-zA-Z ]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        userId: "",
        userNameFlag: false,
      });
    } else {
      this.setState({
        userNameFlag: true,
        userNameErrorMsg: "Use only Alphabets",
      });
    }
    this.setState({ userName: e.target.value });
  };

// emailHandler Function ...................................
 emailHandler = (e) => {
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+[.][a-zA-Z]{2,3}$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        emailId: "",
        emailFlag: false,
      });
    } else {
      this.setState({
        emailFlag: true,
        emailErrorMsg: "Enter Valid Email",
      });
    }
    this.setState({ email: e.target.value });
  };
// phoneHandler Function  ...................................
  phoneHandler = (e) => {

    const reg = /[\d]{3}-[\d]{3}-[\d]{4}$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ phoneFlag: false });
    } else {
      this.setState({
        phoneFlag: true,
        phoneErrorMsg: "Enter Valid Phone Number",
      });
    }
    this.setState({ phone: e.target.value });
  };

  

  bestTimeHandler = (bestTime)=>{
    console.log("Best Time to Call change", bestTime)
      this.setState({
        bestTime: bestTime, bestTimeFlag: false
      });
    // }
    
  }


// requestHandler Function ...................................
requestHandler= e => {
  this.setState({ request: e.target.value, requestFlag: false  });
}


// Scroll Function .............................................

scrollToUserName = () => {
  this.userName.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
}
scrollToNumber= () => {
  this.number.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
}
  //  submitHandler  .............................................


  submitHandler = () => {
     if (this.state.userNameFlag || this.state.userName === "") {
        this.setState({
          userNameErrorMsg:
            this.state.userNameFlag && this.state.userName.length > 0
              ? this.state.userNameErrorMsg
              : "Field Required",
          userNameFlag: true,
        });
        this.scrollToUserName();
      } else if (this.state.phoneFlag || this.state.phone === "") {
        this.setState({
          phoneErrorMsg:
            this.state.phoneFlag && this.state.phone.length > 0
              ? "Enter Valid Phone Number"
              : "Field Required",
          phoneFlag: true,
        });
        this.scrollToNumber();
      } else if (this.state.emailFlag || this.state.email === "") {
        this.setState({
          emailErrorMsg:
            this.state.emailFlag && this.state.email.length > 0
              ? this.state.emailErrorMsg
              : "Field Required",
          emailFlag: true,
        });
        this.scrollToNumber();
      } 
      else {
        
      
    

        const data = {
          Last_Name:this.state.userName,
          Email_ID:this.state.email,
          Phone_Number:this.state.phone,
          Best_Time_to_Call:this.state.bestTime,
          Request:this.state.request,
          //Time_Stamp_Created: moment().utc(now).format('MMMM Do YYYY, HH:mm:ss z'),
          //Time_Stamp: moment().format('YYYY-MM-DD HH:mm:ss')
          Time_Stamp: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          User_Type: "Partner",
          Subject:"Partner Submitted Request from Landing Page"
        };

        console.log("Without Error!!!!")
        console.log("contact submit", data)
        //return;

        //return;

        this.props.setZohoLoading(true);

        Axios.request({
          method: "POST",
          url: `${ROOT_URL}/api/contact/send_transitPerx_Demo_Data`,
          headers: { 'Content-Type': 'application/json'},
          data: JSON.stringify(data),
        })
        .then((response)=>{
          
          if (response.status == "200"){
            //Show Loader when request is processing
            //this.props.setZohoContactLoading(false)
            this.props.setZohoLoading(false);
            //alert("Zoho Contact Send Successful")
            this.props.setShowThankYou(true)
            this.props.resetStates()
          }
        })
        .catch((error)=>{
          console.log("Error in sending contact lead to Zoho", error)
          this.props.setZohoLoading(false);
          this.props.setShowThankYou(true);
          this.props.resetStates()
        })
     
      }

        

  };

    render() {
        const {
            email,
            emailErrorMsg,
            emailFlag,
            userName,
            userNameErrorMsg,
            userNameFlag,
            phone,
            phoneErrorMsg,
            phoneFlag,
            // bestTime,
            // bestTimeErrorMsg,
            // bestTimeFlag,
            request,
            requestErrorMsg,
            requestFlag
        } = this.state
        return (
            <div className="perx-schdule">
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="prex-heading-two">Schedule a Demo</h1>
                            </div>
                        </div>
                        <div className="row">
                <div className="col-sm-12">
                    <div className="input-b">
                        <LabelInput
                        type="text"
                        label="Name"
                        star="*"
                        // id={this.state.userId}
                        name="Username"
                        inputValue={userName}
                        errorMsg={userNameErrorMsg}
                        flag={userNameFlag}
                        maxLength={100}
                        onChange={this.userNameHandler}
                        />
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-sm-6">
                    <div className="input-b margin">
                        <InputMask
                         type="text"
                         label="Phone Number"
                         star="*"
                         name="phone"
                         inputValue={phone}
                         errorMsg={phoneErrorMsg}
                         flag={phoneFlag}
                         onChange={this.phoneHandler}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input-b margin">
                        <LabelInput
                         type="email"
                         label="Email Address"
                         star="*"
                         name="email"
                         id={this.state.emailId}
                         inputValue={email}
                         errorMsg={emailErrorMsg}
                         flag={emailFlag}
                         onChange={this.emailHandler}
                        />
                    </div>
                </div>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.userName = el;
              }}
            ></div>
            <div className="row">
                <div className="col-sm-12">
                <div className="input-b margin" style={{display:"none"}}>
                <TimeToCallContainer
                    //bestTimeFlag={this.state.bestTimeFlag}
                    stateFlag={this.state.bestTimeFlag}
                    // hideFirst={true}
                    errorMsg={this.state.bestTimeErrorMsg}
                    bestTimeHandler={this.bestTimeHandler}
                />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="input-b">
                        <LabelTextAreaInput
                        type="Request"
                        // star="*"
                        maxLength={2000}
                        label="Request"
                        name="request"
                        inputValue={request}
                        errorMsg={requestErrorMsg}
                        flag={requestFlag}
                        onChange={this.requestHandler}
                        />
                    </div>
                </div>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.number = el;
              }}
            ></div>
            <div className="row">
            <div className="col-sm-12">
                <div className="help-form-btn call-sh margin">
                <button
                  className="help-submit-btn"
                onClick={this.submitHandler}
                >
                  Submit
                </button>
                </div>
            </div>
            </div>
                </div>
            </div>
        )
    }
}

export default TransitPerxSchedule
