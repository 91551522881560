import { connect } from "react-redux";
import AppView from "./App_View";
import {AppServer} from "./server"
import {AppActions} from './AppConstants'
import {withCookies} from "react-cookie"
const mapStateToProps = state => {
    console.log(state)
    return {
        App_GET_status:state.AppReducer.App_Status,
        App:state.AppReducer.AppDetails,
        App_Msg:state.AppReducer.App_Msg,
        RiderUser:state.AppReducer.RiderUser,
        RiderPoints:state.AppReducer.RiderPoints,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        // API call for getting details from Strapi Again
        // getTransitPerx: () => {
        //     dispatch( AppServer.getTransitPerx() )
        // },

        getApp: (data) => {

           dispatch( AppServer.getApp(data) )
        },
        resetReducerState:()=>{
            dispatch({type:AppActions.App_GET.NEW})
        },
        updateRetailerVersionTerms:(data)=>{
            dispatch( AppServer.updateRetailerVersionTerms(data) )
        }
    }
};
const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppView)

export default withCookies(AppContainer);