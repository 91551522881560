import { TermAndConditionsStatus, TermAndConditionsActions } from './TermAndConditionsConstants';
import { TransitPerxStatus, TransitPerxActions } from '../TransitPerx/TransitPerxConstants';

const TermAndConditions_initialState = {
    TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.NEW,
    TermAndConditions_Msg: '',
    TermAndConditionsDetails: {},
    RiderPoints:0,
    encrypted_key:false,
    RiderUser:false,

    TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.NEW,
    TransitPerx_Message: '',
    TransitPerxDetails: {},
    Flags:false
}
export default  function  (state = TermAndConditions_initialState, action) {
    switch (action.type) {
        case TermAndConditionsActions.TermAndConditions_GET.LOADING:
            return { ...state, TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.LOADING }
            case TermAndConditionsActions.TermAndConditions_GET.NEW:
                return { ...state, TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.NEW, TermAndConditionsDetails: [] }
    
        case TermAndConditionsActions.TermAndConditions_GET.FAILED:
            
            return { ...state,  TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.FAILED, TermAndConditions_Msg:action.error}
        case TermAndConditionsActions.TermAndConditions_GET.SUCCESS:
          //console.log(action.payload)
          return { ...state,  TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.SUCCESS, TermAndConditionsDetails:action.payload}
          
          case TermAndConditionsActions.TermAndConditions_GET.NOTACTIVATED:
    
          return { ...state,  TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_GET.NOTACTIVATED, TermAndConditions_Msg:action.message}
          
          case TermAndConditionsActions.TermAndConditions_POST.SUCCESS:
          
            return { ...state,  TermAndConditions_Status: TermAndConditionsStatus.TermAndConditions_POST.SUCCESS}
        
            case TransitPerxActions.TransitPerx_Post.LOADING:
            return { ...state, 
                TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.LOADING,TransitPerx_Message: '',
                TransitPerxDetails: {}
            }
            case TransitPerxActions.TransitPerx_Post.NEW:
                return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.NEW}

            case TransitPerxActions.TransitPerx_Post.FAILED:
           
            return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.FAILED, TransitPerxDetails: action.payload, TransitPerx_Message: action.error, Flags:action.Flags }
        case TransitPerxActions.TransitPerx_Post.SUCCESS:
            return { ...state, TransitPerx_Status: TransitPerxStatus.TransitPerx_Post.SUCCESS, TransitPerxDetails: action.payload, TransitPerx_Message: action.message }
        
            
        default:
            return { ...state
            }
    }
}
