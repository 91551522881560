export const CheckDuplicateBusinessActions= {
	
	CheckDuplicateBusiness_Post :{
		NEW:"CheckDuplicateBusiness_Post_NEW",
		SUCCESS:"CheckDuplicateBusiness_Post_SUCCESS",
		FAILED:"CheckDuplicateBusiness_Post_FALIURE",
		LOADING:"CheckDuplicateBusiness_Post_LOADING",
	}
}


export const CheckDuplicateBusinessStatus ={

	CheckDuplicateBusiness_Post :{
		NEW:"CheckDuplicateBusiness_Post_NEW",
		SUCCESS:"CheckDuplicateBusiness_Post_SUCCESS",
		FAILED:"CheckDuplicateBusiness_Post_FALIURE",
		LOADING:"CheckDuplicateBusiness_Post_LOADING",
		
	}

}
