import { connect } from "react-redux";
import AccountPaymentView from "./AccountPayment_View";
import {AccountPaymentServer} from "./server"
import {AccountPaymentActions} from './AccountPaymentConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        AccountPayment_post_status:state.AccountPaymentReducer.AccountPayment_Status,
        AccountPayment_Message:state.AccountPaymentReducer.AccountPayment_Message,
        AccountPayment:state.AccountPaymentReducer.AccountPaymentDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateAccountPayment: (data, bussinessId) => {
        //     dispatch( AccountPaymentServer.updateAccountPayment(data, bussinessId) )
        //  },

        savePaymentAccount: (data) => {

           dispatch( AccountPaymentServer.savePaymentAccount(data) )
        },
        resetReducerState: () => {
            dispatch({type:AccountPaymentActions.AccountPayment_Post.NEW})
         },
        
    }
};
const AccountPaymentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPaymentView)

export default AccountPaymentContainer;