import React, { Component, Fragment } from "react";
import { AccountPaymentStatus } from "./AccountPaymentConstants";
import AccountPayment from "./AccountPayment";
import Loading from "../../Utils/Loading";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class AccountPaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false,
    };
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  getScreen(status) {
    switch (status) {
      case AccountPaymentStatus.AccountPayment_Post.NEW:
        return (
          <Fragment>
            <AccountPayment
              savePaymentAccount={this.props.savePaymentAccount}
              BussinessId={this.props.BussinessId}
              BussinessName={this.props.BussinessName}
              email={this.props.email}
              ProgramFee = {this.props.ProgramFee}
            />
          </Fragment>
        );

      case AccountPaymentStatus.AccountPayment_Post.SUCCESS:
        
        return (
          <Fragment>
            <AccountPayment
              savePaymentAccount={this.props.savePaymentAccount}
              BussinessId={this.props.BussinessId}
              BussinessName={this.props.BussinessName}
              email={this.props.email}
              ProgramFee = {this.props.ProgramFee}
            />
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="success">
                {this.props.AccountPayment_Message}
              </Alert>
            </Snackbar>
          </Fragment>
        );
      case AccountPaymentStatus.AccountPayment_Post.FAILED:
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <Alert onClose={this.closeSnackBar} severity="error">
                {this.props.AccountPayment_Message}
              </Alert>
            </Snackbar>
            <AccountPayment
              savePaymentAccount={this.props.savePaymentAccount}
              BussinessId={this.props.BussinessId}
              BussinessName={this.props.BussinessName}
              email={this.props.email}
              ProgramFee = {this.props.ProgramFee}
            />
          </Fragment>
        );
      case AccountPaymentStatus.AccountPayment_Post.LOADING:
        this.state.open = true;
        this.state.success = false;
        return <Loading />;
      
      default:
        break;
    }
  }
  render() {
    // alert(this.props.AccountPayment_post_status)
    return this.getScreen(this.props.AccountPayment_post_status);
  }
}

export default AccountPaymentView;
