import { NjtSignUpFormStatus, NjtSignUpFormActions } from './NjtSignUpFormConstants';

const NjtSignUpForm_initialState = {
    NjtSignUpForm_Status: NjtSignUpFormStatus.NjtSignUpForm_Post.NEW,
    NjtSignUpForm_Message: '',
    NjtSignUpFormDetails: [],
}
export default function (state = NjtSignUpForm_initialState, action) {
    switch (action.type) {
        case NjtSignUpFormActions.NjtSignUpForm_Post.LOADING:
            return { ...state, 
            NjtSignUpForm_Status: NjtSignUpFormStatus.NjtSignUpForm_Post.LOADING,NjtSignUpForm_Message: '',
            NjtSignUpFormDetails: []
         }
        case NjtSignUpFormActions.NjtSignUpForm_Post.NEW:
            return { ...state, NjtSignUpForm_Status: NjtSignUpFormStatus.NjtSignUpForm_Post.NEW}

        case NjtSignUpFormActions.NjtSignUpForm_Post.FAILED:
          
            return { ...state, NjtSignUpForm_Status: NjtSignUpFormStatus.NjtSignUpForm_Post.FAILED, NjtSignUpFormDetails: action.payload, NjtSignUpForm_Message: action.error}
        case NjtSignUpFormActions.NjtSignUpForm_Post.SUCCESS:
            return { ...state, NjtSignUpForm_Status: NjtSignUpFormStatus.NjtSignUpForm_Post.SUCCESS, NjtSignUpFormDetails: action.payload }

         
        default:
            return { ...state }
    }
}
