import React from 'react'
import './LabelInput.css'
function LabelInput(props) {

    const { idError, label = 'label', id="" ,type = "text", inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, readOnly = false, maxLength, disabled, inputStyle, star,refrence } = props
    return (
        <div className="li_container" style={containerStyle}>
            <label htmlFor={id} className="li_label">{label}<span style={{color: "red"}}>{star}</span></label>
            <div className="li_input_container">
                <input
                    ref={(el) => {
                    if(refrence){
                        refrence(el)
                    }
                     }}
                     aria-describedby={flag ? idError :""} aria-required="true" aria-invalid={ flag ? "true":"false"}  data-rule={type} className="li_input form-control" id={id} style={inputStyle} type={type} placeholder={placeholder} name={name} value={inputValue} onChange={onChange} readOnly={readOnly} maxLength={maxLength} disabled={disabled} />
                <div id={idError} aria-hidden={flag ? "false":"true"} role="alert" style={{ display: flag ? 'block' : 'none' }}>
                    <p className="li_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>
                </div>
            </div>
        </div>
    )
}

export default LabelInput
