//****************** Server Config  ******************;
import preval from 'preval.macro'

export const ROOT_URL =preval`module.exports = process.env.RETAILER_LANDING_PAGE_API_URL` 
export const VERSION_NO =preval`module.exports = process.env.RETAILER_LANDING_PAGE_WEB_VERSION_NO` 
export const ADMIN_API_URL =preval`module.exports = process.env.ADMIN_API_URL` 
export const CMS_URL =preval`module.exports = process.env.CMS_URL`
export const RETAILER_API_URL =preval`module.exports = process.env.RETAILER_API_URL`
export const RETAILER_WEB_URL =preval`module.exports = process.env.RETAILER_WEB_URL`
export const RIDER_WEB_URL =preval`module.exports = process.env.RIDER_WEB_URL`

export const RETAILER_LANDING_PAGE_WEB_URL =preval`module.exports = process.env.RETAILER_LANDING_PAGE_WEB_URL`

// ***************** Google CAPTCHA Key **********************************
export const CAPTCHA_KEY =preval`module.exports = process.env.CAPTCHA_KEY`


//********************** Retailer Landing Page Branding**************************/
export const RETAILER_FAVICON_ID = preval`module.exports = process.env.RETAILER_FAVICON_ID`
export const RETAILER_LANDING_PAGE_ID = preval`module.exports = process.env.RETAILER_LANDING_PAGE_ID`

//********************** NJT Partner Landing Page Information **************************/
export const NJT_PARTNER_LANDING_PAGE_ID = preval`module.exports = process.env.NJT_PARTNER_LANDING_PAGE_ID`


// ********************* Google Analytics for Retailer Landing Page *****************
export const RETAILER_LANDINGPAGE_GA_CODE = preval`module.exports = process.env.RETAILER_LANDINGPAGE_GA_CODE`

// ********* Support Number********************************
export const SUPPORT_NUMBER = preval`module.exports = process.env.SUPPORT_NUMBER`


/* ************************************************* */
/* ************************************************* */
/* ********** Images Url Dynamic Const export S3 sever************* */
/* ************************************************* */
/* ************************************************* */

export const STATIC_IMAGES_URL = preval`module.exports = process.env.STATIC_IMAGES_URL`
export const LANDING_PAGE_END_BRANDING_IMAGE_URL = preval`module.exports = process.env.LANDING_PAGE_END_BRANDING_IMAGE_URL`


/* ************************************************* */
/* ************************************************* */
/* ********** Branding Theme sever************* */
/* ************************************************* */
/* ************************************************* */


export const BRANDING = preval`module.exports = process.env.BRANDING`

export const TRANSIT_COMPANY_ID = preval`module.exports = process.env.TRANSIT_COMPANY_ID`
export const COOKIE_DOMAIN = preval`module.exports = process.env.COOKIE_DOMAIN`