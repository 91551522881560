
//******************Exchange Rate Server Calls******************;

import {AppActions} from './AppConstants';
import store from './Store/store'
import {CMS_URL, NJT_PARTNER_LANDING_PAGE_ID} from './Config/Config';
import {failure_messages} from "Messages"


export const AppServer = {
  getApp:getApp,
};

//******************Insert Apps******************;

function getApp(data){
      fetch(CMS_URL + `/njt-partner-landing-pages/${NJT_PARTNER_LANDING_PAGE_ID}`, {
          method: 'GET',
          mode: 'cors',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             console.log("Response of Strapi", response)

             if(response){        
             
             store.dispatch({type:AppActions.App_GET.SUCCESS,payload:response});
             return;
            
            }
            else
            {
              store.dispatch({type:AppActions.App_GET.FAILED, error:response.error});
              return ;
              }    
           }).catch((error) => {
            //  alert(error)
             store.dispatch({type:AppActions.App_GET.FAILED, error:failure_messages.unExpectedError});
              })
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AppActions.App_GET.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:AppActions.App_GET.LOADING}

};


