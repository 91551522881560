import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './ThankYouPage.css'

const modalRoot = document.getElementById('NotEnoughPoints')
function Modal(props) { 
  const [domReady, setDomReady] = React.useState(false)

  React.useEffect(() => {
    setDomReady(true)
  })

  return domReady 
    ? ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          zIndex:9991,
          minHeight: "100vh"
        }}>
        <div
        className='responsive_modal'
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '8px',
            display: 'inline-block',
            margin: '1rem',
            position: 'relative',
            width: '540px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
            zIndex:99999
          }}
        >
            <div className="help-model">
            <div id="thank_modal" className="thanks-wrap">
              <div className="container">
                  <div  className="row">
                      <div className="col-sm-12">
                      <div className="thank_box_new">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle className="path circle" fill="none" stroke="#AB3495" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <polyline className="path check" fill="none" stroke="#AB3495" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
              </svg>
                  <div className="thank-txt-box-new">
                      <div className="thaks-txt-signup">
                      Thank you for signing up!<br />
                      An NJT Rewards team member will be in touch shortly.
                      </div>
                  </div>
              </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="modal_signup">
              <button onClick={props.onClose} id="okay_btn_modal" className="btn-yes help-submit-btn btn-style">Okay</button>   
          </div>
          </div>
          {props.children}
 
        </div>
      </div>,
      modalRoot)
    : null
}

  class ThankYouModal extends React.Component {
    state = {showModal: false}
   
    render() {
      return (
        <div
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
         
          
              <Modal onClose={()=> this.props.handleShowThanksModal(false)}>
              </Modal>
    
        </div>
      )
    }
  }


export default ThankYouModal
