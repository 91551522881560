
//******************Exchange Rate Server Calls******************;

import { TimeToCallActions } from './TimeToCallConstants';
import store from '../../../Store/store'
import { ROOT_URL } from '../../../Config/Config';


export const TimeToCallServer = {
  //  TimeToCall:TimeToCall,
  getTimeToCall: getTimeToCall,
  //  updateTimeToCall:updateTimeToCall
};

//****************** TimeToCall with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function getTimeToCall(data, token) {

  fetch(ROOT_URL + '/api/ref/getTimeToCall', {
    method: 'Post',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-access-token': token },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        // console.log('Business quota details', response.data)
        console.log("time to call from db", response.data)
  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        //console.log(response.data)

        let TimeToCall = response.data.map((item, index)=>{
          return { value: item.name, name: item.name }
        })

        TimeToCall.unshift({value:'', name: 'Select'})

        store.dispatch({ type: TimeToCallActions.TimeToCall_GET.SUCCESS, payload: TimeToCall});
        return;
      } else {
        store.dispatch({ type: TimeToCallActions.TimeToCall_GET.FAILED });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: TimeToCallActions.TimeToCall_GET.FAILED });
    })
  }).catch((error) => {
    store.dispatch({ type: TimeToCallActions.TimeToCall_GET.FAILED });
  })
  return { type: TimeToCallActions.TimeToCall_GET.LOADING }

};
