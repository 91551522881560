import PropTypes from 'prop-types'
import React, { Component } from 'react'
import "../NewHomeLayout.css"

export default class HeroSection extends Component {
  render() {
    const { heading, link,linkText,heroImage } = this.props;
    return (
      <div className='hero_section'>
        {/* <img src={heroImage} alt={"hero section image"} /> */}
        <div style={{backgroundImage: `url(${heroImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            <div className='content_box'>
              <div className='container'>
              <div className='row'>
                <div className='col-sm-12 col-md-8'>
               {heading}
               <div className='partner_link_section'>
                <a 
                  href={link}
                  className="promotion-submit-btn"
                  target="_blank"
                >
                  BECOME A PARTNER
                </a>
                </div>
                </div>
                </div>
              </div>
            </div>
       </div>
      </div>
    )
  }
}
