import React, { Component } from "react";
import { PlaidLink } from "react-plaid-link";
import { PlaidConfiguration } from "../../Config/Plaid-config";

export class AccountPayment extends Component {
  constructor() {
    super();

    this.state = {
      transactions: [],
    };
  }

  handleOnSuccess = (public_token, metadata) => {
    
    this.postPaymentMethods(public_token, metadata.account_id);
  };
  handleOnExit() {
    // handle the case when your user exits Link
    // For the sake of this tutorial, we're not going to be doing anything here.
  }

  postPaymentMethods = (pulic_token, account_id) => {
    const data = {
      business_id: this.props.BussinessId,
      business_name: this.props.BussinessName,
      email: this.props.email,
      ACCOUNT_ID: account_id,
      PLAID_LINK_PUBLIC_TOKEN: pulic_token,
      ProgramFee :this.props.ProgramFee
    };
    console.log(data);
    this.props.savePaymentAccount(data);
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div className="btn-link-part">
          <PlaidLink
            clientName="Retailer Plaid Setup"
            env={PlaidConfiguration.ENV}
            publicKey={PlaidConfiguration.PLAID_PUBLIC_KEY}
            onExit={this.handleOnExit}
            onSuccess={this.handleOnSuccess}
            selectAccount={true}
            product={["auth", "transactions"]}
          >
            Add Payment Account
          </PlaidLink>
        </div>
      </div>
    );
  }
}

export default AccountPayment;
