
//******************Exchange Rate Server Calls******************;

import { AccountPaymentActions } from './AccountPaymentConstants';
import store from '../../Store/store'
import { ROOT_URL } from '../../Config/Config';
import {failure_messages} from 'Messages'

export const AccountPaymentServer = {
  //  getAccountPayment:getAccountPayment,
  savePaymentAccount: savePaymentAccount,

};

//******************Insert AccountPayments******************;

function savePaymentAccount(data) {

  fetch(ROOT_URL + '/api/plaidstripe/postACHAccount', {
    method: 'Post',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status + "" === "200") {

        store.dispatch({ type: AccountPaymentActions.AccountPayment_Post.SUCCESS, payload: data, message:response.message });
      } else {
        // alert("test")
        store.dispatch({ type: AccountPaymentActions.AccountPayment_Post.FAILED,  payload: data, error:response.error});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: AccountPaymentActions.AccountPayment_Post.FAILED,payload: data, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: AccountPaymentActions.AccountPayment_Post.FAILED, payload: data, error:failure_messages.unExpectedError });
  })
  return { type: AccountPaymentActions.AccountPayment_Post.LOADING }

};

//******************Get AccountPayments******************;


//******************Update AccountPayment******************;
