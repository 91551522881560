import { TimeToCallStatus, TimeToCallActions } from './TimeToCallConstants';

const TimeToCall_initialState = {
    TimeToCall_Status: TimeToCallStatus.TimeToCall_GET.NEW,
    TimeToCall_Error: '',
    TimeToCall: [],
}
export default  function  (state = TimeToCall_initialState, action) {
    switch (action.type) {
        case TimeToCallActions.TimeToCall_GET.LOADING:
            return { ...state, TimeToCall_Status: TimeToCallStatus.TimeToCall_GET.LOADING }
            case TimeToCallActions.TimeToCall_GET.NEW:
                return { ...state, TimeToCall_Status: TimeToCallStatus.TimeToCall_GET.NEW }
    
        case TimeToCallActions.TimeToCall_GET.FAILED:
            return { ...state,  TimeToCall_Status: TimeToCallStatus.TimeToCall_GET.FAILED}
        case TimeToCallActions.TimeToCall_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  TimeToCall_Status: TimeToCallStatus.TimeToCall_GET.SUCCESS, TimeToCall:action.payload}
        
        default:
            return { ...state,
            }
    }
}
