import { connect } from "react-redux";
import GetStatesView from "./GetStates_View";
import {GetStatesServer} from "./server"
const mapStateToProps = state => {
    console.log(state)
    return {
        GetStates_GET_status:state.GetStatesReducer.GetStates_Status,
        GetStates:state.GetStatesReducer.GetStates,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getGetStates: () => {

           dispatch( GetStatesServer.getGetStates() )
        },
        
    }
};
const GetStatesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetStatesView)

export default GetStatesContainer;