import { connect } from "react-redux";
import BusinessProfileView from "./GetInTouch";
import {BusinessProfileServer} from "./server"
import {BusinessProfileActions} from './BusinessProfileConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BusinessProfile_post_status:state.BusinessProfileReducer.BusinessProfile_Status,
        BusinessProfile_Message:state.BusinessProfileReducer.BusinessProfile_Message,
        BusinessProfile:state.BusinessProfileReducer.BusinessProfileDetails,
        Flags:state.BusinessProfileReducer.Flags,
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        submitBusinessApplication: (data) => {
            dispatch( BusinessProfileServer.submitBusinessApplication(data) )
         },

        saveBusinessProfile: (data) => {

           dispatch( BusinessProfileServer.saveBusinessProfile(data) )
        },

        saveRetailerData: (data) => {

            dispatch( BusinessProfileServer.saveRetailerData(data) )
         },


        resetReducerState: () => {
            dispatch({type:BusinessProfileActions.BusinessProfile_Post.NEW})
         },
         updateBusinessProfile: (data, token) =>{
            dispatch( BusinessProfileServer.updateBusinessProfile(data, token) )
         }
        //  GotoContract: () => {
        //     dispatch({type:BusinessProfileActions.BusinessProfile_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const BusinessProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessProfileView)

export default BusinessProfileContainer;